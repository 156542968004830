import { ROLES } from "model/constants";
import AdminSettings from "views/pages/AdminSettings/AdminSettings";
import AnalitycsView from "views/pages/analitycs/Analitycs";
import Lock from "views/pages/auth/Lock";
import Login from "views/pages/auth/Login";
import Register from "views/pages/auth/Register";
import CustomersView from "views/pages/contacts/Customers";
import LeadsView from "views/pages/contacts/Leads";
import SubcontractorsViews from "views/pages/contacts/Subcontractors";
import PruDashboard from "views/pages/dashboards/PruDashboard";
import Pricing from "views/pages/examples/Pricing";
import Profile from "views/pages/examples/Profile";
import MapsView from "views/pages/maps/Maps";
import SalesPipeline from "views/pages/pipeline/Pipeline";
import ProjectView from "views/pages/projects/Project";
import ProjectsView from "views/pages/projects/Projects";
import RolesView from "views/pages/Roles/Roles";
import MaterialsView from "views/pages/suppliers/Materials";
import SuppliersView from "views/pages/suppliers/Suppliers";
import Tickets from "views/pages/analitycs/components/TicketsOverview";

export const defaultRoute = "/admin/dashboard";

const routes = [
  //  #region Admin
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <PruDashboard />,
    layout: "/admin",
    isProtected: false,
    permissions: [],
  },
  {
    collapse: true,
    state: "contactsCollapse",
    name: "Contacts",
    icon: "ni ni-badge text-default",
    views: [
      {
        path: "/contacts/leads",
        name: "Leads",
        component: <LeadsView />,
        layout: "/admin",
        miniName: "L",
        shortcutIcon: "ni ni-badge text-default",
        isProtected: false,
        permissions: [],
      },
      {
        path: "/contacts/customers",
        name: "Customers",
        component: <CustomersView />,
        layout: "/admin",
        miniName: "C",
        shortcutIcon: "ni ni-single-02 text-default",
        isProtected: false,
        permissions: [],
      },
      {
        path: "/contacts/subcontractors",
        name: "Subcontractors",
        component: <SubcontractorsViews />,
        layout: "/admin",
        miniName: "S",
        shortcutIcon: "ni ni-settings text-default",
        isProtected: false,
        permissions: [],
      },
    ],
  },
  {
    collapse: true,
    state: "suppliersCollapse",
    icon: "ni ni-box-2 text-default",
    name: "Suppliers",
    views: [
      {
        path: "/suppliers/list",
        component: <SuppliersView />,
        layout: "/admin",
        name: "Suppliers",
        shortcutIcon: "ni ni-shop text-default",
        miniName: "S",
        isProtected: false,
        permissions: [],
      },
      {
        path: "/suppliers/material",
        name: "Supply / Material",
        shortcutName: "Material",
        shortcutIcon: "ni ni-box-2 text-default",
        miniName: "M",
        component: <MaterialsView />,
        layout: "/admin",
        isProtected: false,
        permissions: [],
      },
    ],
  },
  /*  {
    path: "/activities",
    name: "Activities",
    icon: "ni ni-ruler-pencil text-default",
    component: <ActivitiesView />,
    layout: "/admin",
  }, */
  {
    path: "/projects",
    name: "Projects",
    icon: "ni ni-ruler-pencil text-default",
    component: <ProjectsView />,
    layout: "/admin",
    isProtected: false,
    permissions: [],
  },
  {
    path: "/projects/:id",
    name: "Project",
    component: <ProjectView />,
    layout: "/admin",
    redirect: true,
    isProtected: false,
    permissions: [],
  },
  {
    path: "/pipeline",
    name: "Pipeline",
    icon: "ni ni-chart-bar-32 text-default",
    component: <SalesPipeline />,
    layout: "/admin",
    isProtected: false,
    permissions: [],
  },
  {
    path: "/tickets",
    name: "Tickets",
    icon: "ni ni-folder-17 text-default",
    component: <Tickets />,
    layout: "/admin",
    isProtected: false,
    permissions: [],
  },
  {
    path: "/analitycs",
    name: "Analitycs",
    icon: "ni ni-ruler-pencil text-default",
    component: <AnalitycsView />,
    layout: "/admin",
    isProtected: true,
    permissions: [ROLES.ADMIN],
  },
  {
    path: "/roles",
    name: "Roles",
    icon: "fa fa-users text-default",
    component: <RolesView />,
    layout: "/admin",
    isProtected: true,
    permissions: [ROLES.ADMIN],
  },
  {
    path: "/settings",
    name: "Admin Settings",
    icon: "fa fa-users text-default",
    component: <AdminSettings />,
    layout: "/admin",
    isProtected: true,
    permissions: [ROLES.ADMIN],
  },
  {
    path: "/maps",
    name: "Maps",
    icon: "fa fa-users text-default",
    component: <MapsView />,
    layout: "/admin",
    isProtected: true,
    permissions: [ROLES.ADMIN],
  },
  //  #endregion

  //  #region Auth
  {
    path: "/pricing",
    name: "Pricing",
    component: <Pricing />,
    layout: "/auth",
    redirect: true,
    isProtected: false,
    permissions: [],
  },
  {
    path: "/login",
    name: "Login",
    component: <Login />,
    layout: "/auth",
    redirect: true,
    isProtected: false,
    permissions: [],
  },
  {
    path: "/register",
    name: "Register",
    component: <Register />,
    layout: "/auth",
    redirect: true,
    isProtected: false,
    permissions: [],
  },
  {
    path: "/lock",
    name: "Lock",
    component: <Lock />,
    layout: "/auth",
    redirect: true,
    isProtected: false,
    permissions: [],
  },
  {
    path: "/profile",
    name: "Profile",
    component: <Profile />,
    layout: "/admin",
    redirect: true,
    permissions: [],
  },
  //  #endregion
];

export default routes;
