import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/file-input/dist/style.css";
import { Dashboard } from "@uppy/react";
import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import { useCallback, useEffect, useState } from "react";
import { Button } from "reactstrap";
import { isImage } from "utils";
import "./ProjectAttachments.css";
import { ProjectImageModal } from "./ProjectImageModal";

export function ProjectAttachments({ internal, tradeId, refreshImages }) {
  const [files, setFiles] = useState([]);
  const [imageModal, setImageModal] = useState(null);
  const { storage } = useFirebase();
  const [uppyInstance] = useState(() =>
    new Uppy({
      inline: true,
      restrictions: {
        allowedFileTypes: [
          "image/*",
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-excel",
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
          ".txt",
        ],
      },
    }).on("upload", async (_uploadId, newFiles) => {
      try {
        if (newFiles.length && internal.current && tradeId) {
          await Promise.all(
            newFiles.map((file) =>
              storage.upload(
                file.data,
                `${COLLECTIONS.projects}/${internal.current}/${tradeId}`
              )
            )
          );
          await getFiles();
          uppyInstance.setState({
            files: [],
          });
          refreshImages();
        }
      } catch (err) {
        console.error("Error uploading files:", err);
      }
    })
  );

  const getFiles = useCallback(async () => {
    const fetchedFiles = await storage.getFiles(
      `${COLLECTIONS.projects}/${internal.current}/${tradeId}`
    );
    const urls = await Promise.all(
      fetchedFiles.map((f) => storage.getFileUrl(f.fullPath))
    );
    setFiles(
      fetchedFiles.map((f, i) => ({
        name: f.name,
        url: urls[i],
        fullPath: f.fullPath,
      }))
    );
  }, [internal, storage, tradeId]);

  const onDeleteFile = async (file) => {
    await storage.delete(file.fullPath);
    setFiles(files.filter((img) => file.name !== img.name));
    refreshImages();
  };

  useEffect(() => {
    // Add existing images from Firebase to Uppy when component mounts
    getFiles();

    return () => {
      uppyInstance.off("file-added");
      uppyInstance.off("upload-success");
      uppyInstance.off("upload-error");
    };
  }, [getFiles, uppyInstance]);

  const onOpenFileModal = (file) => {
    setImageModal(file);
  };

  const onDownloadFile = async () => {
    storage.download(imageModal.fullPath);
  };

  return (
    <>
      <ProjectImageModal
        open={imageModal}
        onClose={() => setImageModal(null)}
        selectedItem={imageModal}
        onDownload={onDownloadFile}
        onDelete={async () => await onDeleteFile(imageModal)}
      />
      <div
        style={{ gap: 18 }}
        className="w-100 h-100 d-flex mx-auto justify-content-center"
      >
        <div className="w-100 h-100">
          <h3>Add new files</h3>
          <Dashboard showProgressDetails uppy={uppyInstance} />
        </div>

        <div className="w-100 h-100">
          <h3>Uploaded files</h3>
          <div
            style={{ marginTop: 20, height: "400px" }}
            className="w-100 overflow-auto"
          >
            {!!files.length ? (
              files.map((file) => (
                <div
                  className="w-100 d-flex justify-content-between"
                  key={file.name}
                >
                  {isImage(file.name) ? (
                    <Button
                      style={{ gap: 10 }}
                      className="d-flex align-items-center"
                      color="link"
                      onClick={() => {
                        onOpenFileModal(file);
                      }}
                    >
                      <img
                       style={{ 
                        width: 50, 
                        height: 50,
                        objectFit: "contain"
                      }}
                        src={file.url}
                        alt={file.name}
                        className="img-fluid"
                      />
                      <span>{file.name}</span>
                    </Button>
                  ) : (
                    <Button
                      style={{ gap: 10 }}
                      className="d-flex align-items-center"
                      color="link"
                      onClick={() => {
                        storage.download(file.fullPath);
                      }}
                    >
                      {/* document icon from aw some icons */}
                      <i
                        style={{
                          fontSize: 50,
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        className="fas fa-file-alt mx-auto"
                      />
                      <span>{file.name}</span>
                    </Button>
                  )}
                  <Button
                    color="link"
                    className="icon-only"
                    onClick={() => onDeleteFile(file)}
                  >
                    <i className="ni ni-fat-remove" />
                  </Button>
                </div>
              ))
            ) : (
              <p className="text-center">There're no images.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
