import { ErrorMessage, Field, FieldArray, useFormikContext } from "formik";
import { COLLECTIONS } from "model/constants";
import { useAuth } from "model/context/auth.context";
import { useFirebase } from "model/context/firebase.context";
import { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Label,
  Row,
  Toast,
  ToastBody,
  UncontrolledTooltip,
} from "reactstrap";
import { handleNumberInput } from "utils";
import { materialProvidersOptions } from "./formData";
const TradeTaskDetailsForm = ({
  errors,
  projectTrades,
  tooltipText,
  values,
  projectId,
  type,
}) => {
  const tradeStatus = [
    "Briefing",
    "Waiting to Buy Materials",
    "Waiting Sub to Start",
    "In Progress",
    "Completed",
  ];
  const { session } = useAuth();
  const { db } = useFirebase();
  const formik = useFormikContext();
  const { setFieldValue, validateField } = formik;
  const tranasctionStatusTimeout = useRef();
  const [transactionStatus, setTransactionStatus] = useState("");
  const handleMaterialProviderChange = (e) => {
    const selectedOptions = Array.from(
      e.target.selectedOptions,
      (option) => option.value
    );
    const newPercentages = { ...values.materialProviderPercentages };

    // Remove percentages for unselected providers
    Object.keys(newPercentages).forEach((provider) => {
      if (!selectedOptions.includes(provider)) {
        delete newPercentages[provider];
      }
    });

    // If only one provider is selected, clear all percentages
    if (selectedOptions.length === 1) {
      setFieldValue("materialProviderPercentages", {});
      // setFieldValue("materialProviderPercentages", { [selectedOptions[0]]: 100 });
      // validateField("materialProviderPercentages");
    } else {
      setFieldValue("materialProviderPercentages", newPercentages);
    }

    setFieldValue("materialProvider", selectedOptions);

    // If there are only two selected fields and they are blank, remove the error message
    if (
      selectedOptions.length >= 2 &&
      Object.values(newPercentages).every((value) => value === "")
    ) {
      setFieldValue("materialProviderPercentages", {}, false);
    }
  };

  const generateNewTransaction = async () => {
    if (setTransactionStatus !== "") setTransactionStatus("");
    const trade = values.trade;
    const estimatedCost = values.estimatedCost;

    const transaction = {
      userName: session.user.displayName || session.user.email,
      createdAt: new Date().toISOString(),
      transactionValue: Number(estimatedCost),
      transactionDate: new Date().toISOString().split("T")[0],
      receiveOrPay: false,
      paid: false,
      transactionMemo: `Trade Task for ${trade}`,
    };

    if (!projectId) {
      console.error("projectId is not set");
      setTransactionStatus("error");
      return;
    }
    try {
      await db.insert(
        `${COLLECTIONS.projects}/${projectId}/transactions`,
        transaction
      );
      setTransactionStatus("success");
    } catch (error) {
      console.error("Error generating transaction:", error);
      setTransactionStatus("error");
    }
  };

  // Toast cleanup for success
  useEffect(() => {
    clearTimeout(tranasctionStatusTimeout.current);
    if (transactionStatus !== "") {
      tranasctionStatusTimeout.current = setTimeout(() => {
        setTransactionStatus("");
      }, 3000);
    }
    return () => {
      clearTimeout(tranasctionStatusTimeout.current);
    };
  }, [transactionStatus]);

  return (
    <Row>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Project Trades
            <sup className={!!errors?.trade ? "text-danger" : ""}>*</sup>
          </label>
          <Field
            required
            name="trade"
            className={`form-control text-dark ${
              errors?.trade ? "is-invalid" : ""
            }`}
            placeholder="Project Trade"
            type={type || "text"}
            list="projectTrades"
          />
          <datalist id="projectTrades">
            {Object.keys(projectTrades)
              .sort()
              .map((item, key) => (
                <option key={key} value={projectTrades[item]}></option>
              ))}
          </datalist>
          <ErrorMessage name="trade" component="div" className="text-danger" />
        </FormGroup>
        <FormGroup>
          <Label
            className="form-control-label font-weight-light"
            id="materialProviderLabel"
          >
            Material Provider
            <sup className={!!errors?.materialProvider ? "text-danger" : ""}>
              *
            </sup>
          </Label>
          <i
            className="fa fa-info-circle"
            id="materialProviderInfoIcon"
            style={{ marginLeft: "5px", cursor: "pointer" }}
          ></i>
          <UncontrolledTooltip
            placement="right"
            target="materialProviderInfoIcon"
          >
            {tooltipText}
          </UncontrolledTooltip>
          <Field
            as="select"
            required
            name="materialProvider"
            className={`form-control text-dark ${
              errors?.materialProvider ? "is-invalid" : ""
            }`}
            multiple
            onChange={handleMaterialProviderChange}
            type={type || ""}
          >
            {materialProvidersOptions.map((provider, key) => (
              <option key={key} value={provider.value}>
                {provider.label}
              </option>
            ))}
          </Field>
          <ErrorMessage
            name="materialProvider"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        {values.materialProvider.length > 1 && (
          <FieldArray
            name="materialProviderPercentages"
            render={(arrayHelpers) => (
              <FormGroup>
                <Label className="form-control-label font-weight-light">
                  Material Provider Percentages
                </Label>
                {values.materialProvider.map((provider, index) => (
                  <div key={index} className="d-flex align-items-center">
                    <Col md="3">
                      <Label className="form-control-label font-weight-light">
                        {
                          materialProvidersOptions.find(
                            (opt) => opt.value === provider
                          ).label
                        }
                      </Label>
                    </Col>
                    <Col md="8" className="d-flex align-items-center">
                      <Field
                        name={`materialProviderPercentages.${provider}`}
                        className={`form-control text-dark ${
                          errors?.materialProviderPercentages
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder={`Percentage for ${
                          materialProvidersOptions.find(
                            (opt) => opt.value === provider
                          ).label
                        }`}
                        type="text"
                        onInput={handleNumberInput}
                        onBlur={() => {
                          validateField("materialProviderPercentages");
                        }}
                      />
                      <span>%</span>
                    </Col>
                  </div>
                ))}
                {errors?.materialProviderPercentages && (
                  <div className="text-danger">
                    {errors?.materialProviderPercentages}
                  </div>
                )}
              </FormGroup>
            )}
          />
        )}
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Estimated Cost
            <sup className={!!errors?.estimatedCost ? "text-danger" : ""}>
              *
            </sup>
          </label>
          <div className="d-flex align-items-center">
            <Field
              required
              name="estimatedCost"
              className={`form-control text-dark ${
                errors?.estimatedCost ? "is-invalid" : ""
              }`}
              placeholder="Estimated Cost"
              onInput={handleNumberInput}
              type={type || "text"}
              inputMode="numeric"
            />
            <Button
              style={{
                padding: "3px 10px",
              }}
              type="button"
              color="primary"
              className="ml-2"
              onClick={generateNewTransaction}
            >
              Generate transaction
            </Button>
          </div>
          <ErrorMessage
            name="estimatedCost"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Total Cost
            <sup className={!!errors?.estimatedCost ? "text-danger" : ""}>
              *
            </sup>
          </label>
          <Field
            name="totalCost"
            className={`form-control text-dark ${
              errors?.totalCost ? "is-invalid" : ""
            }`}
            placeholder="Total Cost"
            onInput={handleNumberInput}
            type={type || "text"}
            inputMode="numeric"
          />
          <ErrorMessage
            name="totalCost"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Trade Status
            <sup className={!!errors?.trade ? "text-danger" : ""}></sup>
          </label>
          <Field
            name="tradeStatus"
            className={`form-control text-dark ${
              errors?.trade ? "is-invalid" : ""
            }`}
            placeholder="Project Trade"
            type={type || "text"}
            list="tradeStatus"
          />
          <datalist id="tradeStatus">
            {Object.keys(tradeStatus)
              .sort()
              .map((item, key) => (
                <option key={key} value={tradeStatus[item]}></option>
              ))}
          </datalist>
          <ErrorMessage name="trade" component="div" className="text-danger" />
        </FormGroup>
      </Col>
      <Col md="6">
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Planned Start Date
            <sup
              className={!!errors?.plannedStartDate ? "text-danger" : ""}
            ></sup>
          </label>
          <Field
            name="plannedStartDate"
            className={`form-control text-dark ${
              errors?.plannedStartDate ? "is-invalid" : ""
            }`}
            placeholder="Planned Start Date"
            type={type || "date"}
          />
          <ErrorMessage
            name="plannedStartDate"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Planned End Date
            <sup
              className={!!errors?.plannedEndDate ? "text-danger" : ""}
            ></sup>
          </label>
          <Field
            name="plannedEndDate"
            className={`form-control text-dark ${
              errors?.plannedEndDate ? "is-invalid" : ""
            }`}
            placeholder="Planned End Date"
            type={type || "date"}
          />
          <ErrorMessage
            name="plannedEndDate"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            Start Date
            <sup className={!!errors?.startDate ? "text-danger" : ""}>
              {errors.startDate}
            </sup>
          </label>
          <Field
            name="startDate"
            className={`form-control text-dark ${
              errors?.startDate ? "is-invalid" : ""
            }`}
            placeholder="Start Date"
            type={type || "date"}
          />
          <ErrorMessage
            name="startDate"
            component="div"
            className="text-danger"
          />
        </FormGroup>
        <FormGroup>
          <label className="form-control-label font-weight-light">
            End Date
            <sup className={!!errors?.endDate ? "text-danger" : ""}>
              {errors.endDate}
            </sup>
          </label>
          <Field
            name="endDate"
            className={`form-control text-dark ${
              errors?.endDate ? "is-invalid" : ""
            }`}
            placeholder="End Date"
            type={type || "date"}
          />
          <ErrorMessage
            name="endDate"
            component="div"
            className="text-danger"
          />
        </FormGroup>
      </Col>
      {/* <Col md="6">
        {values.trade === "Electrical" ? (
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Electrical Specific Field
            </label>
            <Field
              name="electricalSpecificField"
              className={`form-control text-dark`}
              placeholder="Enter Electrical Specific Detail"
              type={type || "text"}
            />
          </FormGroup>
        ) : values.trade === "Plumbing" ? (
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Plumbing Specific Field
            </label>
            <Field
              name="plumbingSpecificField"
              className={`form-control text-dark`}
              placeholder="Enter Plumbing Specific Detail"
              type={type || "text"}
            />
          </FormGroup>
        ) : (
          ""
        )}
      </Col> */}

      {/* ERROR TOAST */}
      {transactionStatus === "error" && (
        <div className="position-absolute fixed-top p-3 bg-danger m-2 rounded">
          <Toast>
            <ToastBody className="text-white">
              Error generating transaction!
            </ToastBody>
          </Toast>
        </div>
      )}
      {transactionStatus === "success" && (
        <div className="position-absolute fixed-top p-3 bg-success m-2 rounded">
          <Toast>
            <ToastBody className="text-white">
              Transaction generated successfully!
            </ToastBody>
          </Toast>
        </div>
      )}
    </Row>
  );
};

export default TradeTaskDetailsForm;
