import CustomNote, { CreateCustomNotes } from "components/Custom-Elements/CustomNote";
import SubContractorNameInput from "components/Custom-Elements/SubContractorNameInput";
import SupplierNameInput from "components/Custom-Elements/SupplierNameInput";
import { COLLECTIONS } from "model/constants";
import { getNestedValue } from "utils";
import * as Yup from "yup";
import { ProjectAttachments } from "../ProjectAttachments";
import TradeTaskDetailsForm from "./TradeTaskDetailsForm";

export const materialProvidersOptions = [
  {
    label: "Pro Utah",
    value: "proUtah",
  },
  {
    label: "Subcontractor",
    value: "subcontractor",
  },
  {
    label: "Client",
    value: "client",
  },
];

const materialQuantityTypeOptions = [
  {
    label: "Unit",
    value: "unit",
  },
  {
    label: "Square Feet",
    value: "squareFeet",
  },
  {
    label: "Linear Feet",
    value: "linearFeet",
  },
  {
    label: "Weight",
    value: "weight",
  },
];

export const materialFormOptions = {
  title: "Materials and Suppliers",
  name: "materials",
  isArray: true,
  defaultValues: {
    materialName: "",
    materialSupplier: "",
    rate: "",
    quantity: "",
    quantityType: null,
    total: "",
  },
  items: [
    {
      label: "Material Name",
      placeholder: "Material Name",
      name: "materialName",
      required: false,
      typeOfInput: "text",
    },
    {
      label: "Material Supplier",
      placeholder: "Material Supplier",
      name: "materialSupplier",
      required: false,
      typeOfInput: SupplierNameInput,
    },
    {
      label: "Rate",
      placeholder: "Rate",
      name: "rate",
      required: false,
      typeOfInput: "text",
      inputMode: "decimal",
      onChange: (e, formik, prefix) => {
        formik.handleChange(e);
        const rate = parseFloat(e.target.value) || 0;
        const quantity =
          parseFloat(getNestedValue(formik.values, `${prefix}.quantity`)) || 0;
        const total = rate * quantity;
        formik.setFieldValue(`${prefix}.total`, total);
      },
    },
    {
      label: "Quantity",
      placeholder: "Quantity",
      name: "quantity",
      required: false,
      typeOfInput: "text",
      inputMode: "decimal",
      onChange: (e, formik, prefix) => {
        formik.handleChange(e);
        const quantity = parseFloat(e.target.value) || 0;
        const rate =
          parseFloat(getNestedValue(formik.values, `${prefix}.rate`)) || 0;
        const total = rate * quantity;
        formik.setFieldValue(`${prefix}.total`, total);
      },
    },
    {
      label: "Quantity Type",
      placeholder: "Quantity Type",
      name: "quantityType",
      required: false,
      options: materialQuantityTypeOptions,
      typeOfInput: "select",
    },
    {
      label: "Total",
      name: "total",
      required: false,
      placeholder: "Total",
      typeOfInput: "text",
      readOnly: true,
    },
  ],
  validationSchema: Yup.object().shape({
    materialName: Yup.string().required("Material Name is required"),
    materialSupplier: Yup.string().required("Material Supplier is required"),
    rate: Yup.number().required("Rate is required"),
    quantity: Yup.number().required("Quantity is required"),
    quantityType: Yup.string()
      .required("Quantity Type is required")
      .oneOf(
        materialQuantityTypeOptions.map((option) => option.value),
        "Please select a valid quantity type"
      ),
    total: Yup.number()
      .required("Total is required")
      .typeError("Total must be a number"),
  }),
};

// We need more hard core validation now
// Total percentage screws up the validation for the form, so this has to be done in a different way
export const tradeTaskValidationSchema = Yup.object().shape({
  trade: Yup.string().required("Material Name is required"),
  materialProvider: Yup.array()
    .of(Yup.string())
    .required("Material Provider is required"),
  materialProviderPercentages: Yup.object().test(
    "total-percentage",
    "Total percentage must be equal to 100% when multiple providers are selected",
    function (value) {
      const materialProviders = this.parent.materialProvider;

      if (!Array.isArray(materialProviders) || materialProviders.length < 2) {
        return true; // Skip validation if less than 2 providers
      }

      if (!value || typeof value !== "object") {
        return false;
      }

      const totalPercentage = Object.values(value).reduce(
        (total, percentage) => {
          const parsedPercentage = parseFloat(percentage);
          return total + (isNaN(parsedPercentage) ? 0 : parsedPercentage);
        },
        0
      );

      return Math.abs(totalPercentage - 100) < 0.01;
    }
  ),

  estimatedCost: Yup.number().required("Estimated Cost is required"),
  plannedStartDate: Yup.date().required("Planned Start Date is required"),
  plannedEndDate: Yup.date().required("Planned End Date is required"),
  startDate: Yup.date(),
  endDate: Yup.date(),
});

const activityAndSuppliersQuantityTypeOptions = [
  {
    label: "Hour",
    value: "hour",
  },
  {
    label: "Unit",
    value: "unit",
  },
  {
    label: "Square Feet",
    value: "squareFeet",
  },
  {
    label: "Linear Feet",
    value: "linearFeet",
  },
  {
    label: "Weight",
    value: "weight",
  },
];
export const activitiesAndSubContractorsFormOptions = {
  title: "Activities and Subcontractors",
  name: "activitiesAndSubContractors",
  isArray: true,
  defaultValues: {
    activityName: "",
    subcontractor: "",
    rate: "",
    quantity: "",
    quantityType: null,
    total: "",
  },
  items: [
    {
      label: "Activity Name",
      placeholder: "Activity Name",
      name: "activityName",
      required: false,
      typeOfInput: "text",
    },
    {
      label: "Subcontractor",
      placeholder: "Subcontractor",
      name: "subcontractor",
      required: false,
      typeOfInput: SubContractorNameInput,
    },
    {
      label: "Rate",
      placeholder: "Rate",
      name: "rate",
      required: false,
      typeOfInput: "decimal",
      onChange: (e, formik, prefix) => {
        formik.handleChange(e);
        const rate = parseFloat(e.target.value) || 0;
        const quantity =
          parseFloat(getNestedValue(formik.values, `${prefix}.quantity`)) || 0;
        const total = rate * quantity;
        formik.setFieldValue(`${prefix}.total`, total);
      },
    },
    {
      label: "Quantity",
      placeholder: "Quantity",
      name: "quantity",
      required: false,
      typeOfInput: "text",
      inputMode: "decimal",
      onChange: (e, formik, prefix) => {
        formik.handleChange(e);
        const quantity = parseFloat(e.target.value) || 0;
        const rate =
          parseFloat(getNestedValue(formik.values, `${prefix}.rate`)) || 0;
        const total = rate * quantity;
        formik.setFieldValue(`${prefix}.total`, total);
      },
    },
    {
      label: "Quantity Type",
      placeholder: "Quantity Type",
      name: "quantityType",
      required: false,
      options: activityAndSuppliersQuantityTypeOptions,
      typeOfInput: "select",
    },
    {
      label: "Total",
      name: "total",
      required: false,
      placeholder: "Total",
      typeOfInput: "text",
      readOnly: true,
    },
  ],
  validationSchema: Yup.object().shape({
    activityName: Yup.string().required("Activity Name is required"),
    subcontractor: Yup.string().required("Subcontractor is required"),
    rate: Yup.number().required("Rate is required"),
    quantity: Yup.number().required("Quantity is required"),
    quantityType: Yup.string()
      .required("Quantity Type is required")
      .oneOf(
        activityAndSuppliersQuantityTypeOptions.map((option) => option.value),
        "Please select a valid quantity type"
      ),
    total: Yup.number()
      .required("Total is required")
      .typeError("Total must be a number"),
  }),
};

export const notesFormOptions = {
  title: "Notes",
  name: "notes",
  collection: COLLECTIONS.projectNotes,
  defaultValues: [
    // {noteId: '-O87xSILTkHaYahHe4iF'}
  ],
  items: [
    {
      label: "Note",
      placeholder: "Note",
      name: "note",
      required: false,
      typeOfInput: CustomNote,
    },
  ],
  validationSchema: Yup.object().shape({
    noteId: Yup.string().nullable(),
  }),
};

export const tabs = [
  {
    title: "Trade Task Details",
    Component: TradeTaskDetailsForm, //Note: This is a break of pattern, but it's the only way to get the form to render
  },
  // {
  //   title: materialFormOptions.title,
  //   Component: CreateGenericFormPartHorizontal(materialFormOptions),
  // },
  // {
  //   title: activitiesAndSubContractorsFormOptions.title,
  //   Component: CreateGenericFormPartHorizontal(
  //     activitiesAndSubContractorsFormOptions
  //   ),
  // },
  {
    title: notesFormOptions.title,
    Component: CreateCustomNotes(notesFormOptions), //Note: This is a break of pattern, but it's the only way to get the form to render
  },
  {
    title: "Attachments",
    Component: ProjectAttachments, //Note: This is a break of pattern, but it's the only way to get the form to render
  },
];

export const defaultTradeTaskFormData = {
  trade: null,
  estimatedCost: "",
  totalCost: "",
  // materialProvider: [],
  // materialProviderPercentages: {}, // Ensure this is included
  plannedStartDate: "",
  plannedEndDate: "",
  startDate: "",
  endDate: "",
};

export const defaultProjectTradesFormData = {
  ...defaultTradeTaskFormData,
  // materials: [materialFormOptions.defaultValues],
  // activitiesAndSubContractors: [
  //   activitiesAndSubContractorsFormOptions.defaultValues,
  // ],
  notes: notesFormOptions.defaultValues,
};
export const formValidationSchema = Yup.object().shape({
  ...tradeTaskValidationSchema.fields,
  // materials: Yup.array()
  //   .of(Yup.object().shape(materialFormOptions.validationSchema.fields))
  //   .required(),
  // activitiesAndSubContractors: Yup.array().of(
  //   activitiesAndSubContractorsFormOptions.validationSchema
  // ),
  notes: Yup.array().of(notesFormOptions.validationSchema),
});
