import { useEffect, useState } from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";

export function CustomerModalZillowInfo({
  errors,
  address,
  onChangeAddress,
}) {
  const [state, setState] = useState(address || {});

  useEffect(() => {
    if (address) {
      setState(address);
    } else {
      setState({});
    }
  }, [address]);

  const onBlur = () => {
    onChangeAddress(state);
  };

  const onInputChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onCheckboxChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
    onChangeAddress({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <h4>{address?.title || "Zillow Info"}</h4>
      <Row>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Zillow Listing Link
            </label>
            <Input
              name="zillowLink"
              className="form-control text-dark"
              placeholder="Zillow Listing Link"
              type="url"
              value={state.zillowLink || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Zillow Last Listing Date
            </label>
            <Input
              name="zillowLastListingDate"
              className="form-control text-dark"
              placeholder="Zillow Last Listing Date"
              type="date"
              value={state.zillowLastListingDate || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Zillow Last Value
            </label>
            <Input
              name="zillowValue"
              className="form-control text-dark"
              placeholder="Zillow Last Value"
              type="number"
              value={state.zillowValue || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Year Built
            </label>
            <Input
              name="yearBuilt"
              className="form-control text-dark"
              placeholder="Year Built"
              type="number"
              value={state.yearBuilt || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Zillow Acreage
            </label>
            <Input
              name="zillowAcreage"
              className="form-control text-dark"
              placeholder="Zillow Acreage"
              type="number"
              value={state.zillowAcreage || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              $/SQFT
            </label>
            <Input
              name="pricePerSqft"
              className="form-control text-dark"
              placeholder="$/SQFT"
              type="number"
              value={state.pricePerSqft || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              HOA Monthly Fee Estimated
            </label>
            <Input
              name="hoaFee"
              className="form-control text-dark"
              placeholder="HOA Fee Estimated"
              type="number"
              value={state.hoaFee || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              What's Special
            </label>
            <Input
              name="special"
              className="form-control text-dark"
              placeholder="What's Special"
              type="textarea"
              value={state.special || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Zillow Description
            </label>
            <Input
              name="zillowDescription"
              className="form-control text-dark"
              placeholder="Zillow Description"
              type="textarea"
              value={state.zillowDescription || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <div className="d-flex align-items-center">
              <small className="form-control-label font-weight-light mr-3">
                Basement?
              </small>
              <div>
                <label className="custom-toggle custom-toggle">
                  <input
                    checked={state.hasBasement || false}
                    type="checkbox"
                    name="hasBasement"
                    onChange={onCheckboxChange}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <div className="d-flex align-items-center">
              <small className="form-control-label font-weight-light mr-3">
                Separated Entrance for Basement
              </small>
              <div>
                <label className="custom-toggle custom-toggle">
                  <input
                    checked={state.hasBasementEntrance || false}
                    type="checkbox"
                    name="hasBasementEntrance"
                    onChange={onCheckboxChange}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </div>
            </div>
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Total Structure Area
            </label>
            <Input
              name="structureArea"
              className="form-control text-dark"
              placeholder="Total Structure Area"
              type="number"
              value={state.structureArea || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Total Interior Livable Area
            </label>
            <Input
              name="livableArea"
              className="form-control text-dark"
              placeholder="Total Interior Livable Area"
              type="number"
              value={state.livableArea || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Finished Area Above Ground
            </label>
            <Input
              name="finishedArea"
              className="form-control text-dark"
              placeholder="Finished Area Above Ground"
              type="number"
              value={state.finishedArea || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Stories
            </label>
            <Input
              name="stories"
              className="form-control text-dark"
              placeholder="Stories"
              type="number"
              value={state.stories || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Zoning Description
            </label>
            <Input
              name="zoningDescription"
              className="form-control text-dark"
              placeholder="Zoning Description"
              type="textarea"
              value={state.zoningDescription || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <div className="d-flex align-items-center">
              <small className="form-control-label font-weight-light mr-3">
                Private Notes
              </small>
              <div>
                <label className="custom-toggle custom-toggle">
                  <input
                    checked={state.privateNotes || false}
                    type="checkbox"
                    name="privateNotes"
                    onChange={onCheckboxChange}
                  />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </label>
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}