import { useEffect, useState } from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";

export function CustomerModalPropertyInfo({
  errors,
  address,
  onChangeAddress,
}) {
  const [state, setState] = useState(address || {});

  useEffect(() => {
    if (address) {
      setState(address);
    } else {
      setState({});
    }
  }, [address]);

  const onBlur = () => {
    onChangeAddress(state);
  };

  const onInputChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onCheckboxChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
    onChangeAddress({
      ...state,
      [e.target.name]: e.target.checked,
    });
  };

  return (
    <>
      <h4>{address?.title || "Property Information"}</h4>
      <Row>
        <Col md="6">
          <Row>
            <Col md="6">
              <FormGroup>
                <div className="d-flex align-items-center">
                  <small className="form-control-label font-weight-light mr-3">
                    Basement Finished?
                  </small>
                  <div>
                    <label className="custom-toggle custom-toggle">
                      <input
                        type="checkbox"
                        name="hasBasementFinished"
                        checked={state.hasBasementFinished || false}
                        onChange={onCheckboxChange}
                      />
                      <span
                        className="custom-toggle-slider rounded-circle"
                        data-label-off="No"
                        data-label-on="Yes"
                      />
                    </label>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label className="form-control-label font-weight-light">
                  Source
                </label>
                <Input
                  name="hasBasementFinishedSource"
                  className={`form-control text-dark ${
                    errors?.hasBasementFinishedSource ? "is-invalid" : ""
                  }`}
                  placeholder="Source"
                  type="text"
                  value={state.hasBasementFinishedSource || ""}
                  onChange={onInputChange}
                  onBlur={onBlur}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Acreage
            </label>
            <Input
              name="acreage"
              className={`form-control text-dark ${
                errors?.acreage ? "is-invalid" : ""
              }`}
              placeholder="Acreage"
              type="number"
              value={state.acreage || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Parcel Number
            </label>
            <Input
              name="parcelNumber"
              className="form-control text-dark"
              placeholder="Parcel Number"
              type="text"
              value={state.parcelNumber || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Ceiling Height(ft)
            </label>
            <Input
              name="ceilingHeight"
              className="form-control text-dark"
              placeholder="Ceiling Height"
              type="text"
              value={state.ceilingHeight || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
        </Col>
        <Col md="6">
          <FormGroup>
            <label className="form-control-label font-weight-light">
              2019 Value
            </label>
            <Input
              name="year2019value"
              className={`form-control text-dark ${
                errors?.year2019value ? "is-invalid" : ""
              }`}
              placeholder="year 2019 value"
              type="number"
              value={state.year2019value || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              2020 Value
            </label>
            <Input
              name="year2020value"
              className={`form-control text-dark ${
                errors?.year2020value ? "is-invalid" : ""
              }`}
              placeholder="year 2020 value"
              type="number"
              value={state.year2020value || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              2021 Value
            </label>
            <Input
              name="year2021value"
              className={`form-control text-dark ${
                errors?.year2021value ? "is-invalid" : ""
              }`}
              placeholder="year 2021 value"
              type="number"
              value={state.year2021value || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              2022 Value
            </label>
            <Input
              name="year2022value"
              className={`form-control text-dark ${
                errors?.year2022value ? "is-invalid" : ""
              }`}
              placeholder="year 2022 value"
              type="number"
              value={state.year2022value || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              2023 Value
            </label>
            <Input
              name="year2023value"
              className={`form-control text-dark ${
                errors?.year2023value ? "is-invalid" : ""
              }`}
              placeholder="year 2023 value"
              type="number"
              value={state.year2023value || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
          <FormGroup>
            <label className="form-control-label font-weight-light">
              2024 Value
            </label>
            <Input
              name="year2024value"
              className={`form-control text-dark ${
                errors?.year2024value ? "is-invalid" : ""
              }`}
              placeholder="year 2024 value"
              type="number"
              value={state.year2024value || ""}
              onChange={onInputChange}
              onBlur={onBlur}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}