/* eslint-disable react-hooks/exhaustive-deps */
import {
  faAddressCard,
  faFileExport,
  faHouseUser,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CustomerModal } from "components/Contacts/CustomerModal";
import { CustomerNotesTimeline } from "components/Contacts/CustomerNotesTmeline";
import { FilterModal } from "components/Contacts/FilterModal"; // Import FilterModal here
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import { customAlphabet as nanoid } from "nanoid";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import slugify from "slugify";

function LeadsView() {
  const { db } = useFirebase();
  const [items, setItems] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [nestedTab, setNestedTab] = useState("1");
  const internal = useRef(
    nanoid("1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ")()
  );
  const [notes, setNotes] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [loadingAddresses, setLoadingAddresses] = useState(false);
  const [, setError] = useState(""); // Define setError correctly
  const [search, setSearch] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  const sortedItems = (items) => {
    if (!sortConfig.key) return items;

    return [...items].sort((a, b) => {
      const aValue = a[sortConfig.key] || "";
      const bValue = b[sortConfig.key] || "";

      if (aValue < bValue) {
        return sortConfig.direction === "asc" ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [filters, setFilters] = useState({
    isVisited: null,
    isInfoConfirmed: null,
    isbidSent: null,
    hasBasementFinished: null,
  });

  const applyFilters = () => {
    setOpenFilterModal(false);
  };

  const filterItems = (items) => {
    return items.filter((item) => {
      const matchesVisited =
        filters.isVisited === null ||
        String(item.isVisited) === filters.isVisited;
      const matchesInfoConfirmed =
        filters.isInfoConfirmed === null ||
        String(item.isInfoConfirmed) === filters.isInfoConfirmed;
      const matchesBidSent =
        filters.isbidSent === null ||
        String(item.isbidSent) === filters.isbidSent;
      const matchesBasementFinished =
        filters.hasBasementFinished === null ||
        String(item.hasBasementFinished) === filters.hasBasementFinished;

      return (
        matchesVisited &&
        matchesInfoConfirmed &&
        matchesBidSent &&
        matchesBasementFinished
      );
    });
  };

  const onSelectItem = useCallback(
    (item) => {
      setSelectedItem(item);
      setAddresses([]);
      if (loadingAddresses) setLoadingAddresses(false);
    },
    [loadingAddresses]
  );

  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.leads, (data) => {
      if (data) {
        const keys = Object.keys(data);
        const items = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          });
          return result;
        }, []);
        setItems(items);
      } else {
        setItems([]);
      }
      if (loading) setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  useEffect(() => {
    if (!openAddModal && selectedItem) {
      setTimeout(() => {
        onSelectItem(null);
      }, 350);
    }
  }, [openAddModal]);

  useEffect(() => {
    if (selectedItem) {
      setNotes(selectedItem.notes || []);
    }
  }, [selectedItem]);

  useEffect(() => {
    const fetchAddresses = async () => {
      if (selectedItem) {
        setLoadingAddresses(true);
        const addressesPromise = selectedItem.addressesId?.map(
          async (addressId) => {
            const addressSnapshot = await db.get("addresses", addressId);
            const address = addressSnapshot?.val();
            if (address) {
              return { ...address, id: addressId };
            }
            return null;
          }
        );
        if (addressesPromise?.length > 0) {
          const addresses = await Promise.all(addressesPromise);
          const filteredAddresses = addresses.filter(
            (address) => address !== null
          );
          if (filteredAddresses?.length > 0) {
            setAddresses(filteredAddresses);
          }
        }
        setLoadingAddresses(false);
      }
    };
    fetchAddresses();
  }, [selectedItem]);

  return (
    <>
      <SimpleHeader
        search={setSearch}
        actions={[
          {
            label: "Filter",
            handler: () => setOpenFilterModal(true), // This will open the filter modal
          },
          {
            label: "Clear Filters",
            handler: () =>
              setFilters({
                isVisited: null,
                isInfoConfirmed: null,
                isbidSent: null,
                hasBasementFinished: null,
              }),
          },
          {
            label: "Add Lead",
            handler: () => {
              onSelectItem(null);
              setOpenAddModal(true);
            },
          },
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="6">
            <Card>
              <CardBody>
                <ListGroup flush>
                  <ListGroupItemHeading>
                    <Row>
                      <Col
                        lg="5"
                        md="6"
                        sm="6"
                        xs="10"
                        onClick={() => handleSort("name")}
                        style={{ cursor: "pointer" }}
                      >
                        Name{" "}
                        {sortConfig.key === "name"
                          ? sortConfig.direction === "asc"
                            ? "▲"
                            : "▼"
                          : ""}
                      </Col>
                      <Col
                        lg="3"
                        className="d-none d-lg-block"
                        onClick={() => handleSort("city")}
                        style={{ cursor: "pointer" }}
                      >
                        City, State{" "}
                        {sortConfig.key === "city"
                          ? sortConfig.direction === "asc"
                            ? "▲"
                            : "▼"
                          : ""}
                      </Col>
                      <Col lg="2" md="2" sm="2" xs="2">
                        Tags
                      </Col>
                      <Col lg="1" md="1" sm="1" xs="1"></Col>
                    </Row>
                  </ListGroupItemHeading>

                  {!items.length && (
                    <ListGroupItem>
                      <p className="text-center">
                        {loading ? "Loading..." : "There are no items yet"}
                      </p>
                    </ListGroupItem>
                  )}
                  {(search
                    ? sortedItems(
                      filterItems(
                        items.filter((item) => {
                          const opts = { lower: true, replacement: " " };
                          const name = slugify(item.name || "", opts);
                          const lastName = slugify(item.lastName || "", opts);
                          const email = slugify(item.email || "", opts);
                          return (
                            name.includes(search) ||
                            lastName.includes(search) ||
                            email.includes(search)
                          );
                        })
                      )
                    )
                    : sortedItems(filterItems(items))
                  ).map((item) => (
                    <ListGroupItem
                      key={item.id}
                      action
                      onClick={() => onSelectItem(item)}
                    >
                      <Row>
                        <Col
                          lg="5"
                          md="5"
                          sm="5"
                          xs="10"
                          className="text-bold text-nowrap text-truncate"
                        >
                          {item.name}, {item.lastName}
                        </Col>
                        <Col
                          lg="3"
                          className="d-none d-lg-block text-nowrap text-truncate"
                        >
                          {item.city}, {item.state}
                        </Col>
                        <Col
                          lg="2"
                          md="2"
                          sm="2"
                          xs="2"
                          className="d-flex flex-row flex-nowrap"
                        >
                          <FontAwesomeIcon
                            icon={faAddressCard}
                            style={{
                              color: item.origin
                                ? item.origin.toLowerCase().includes("scrap")
                                  ? "yellow"
                                  : item.origin
                                    .toLowerCase()
                                    .includes("contacted us") ||
                                    item.origin
                                      .toLowerCase()
                                      .includes("reference from") ||
                                    item.origin
                                      .toLowerCase()
                                      .includes("joist migration") ||
                                    item.origin
                                      .toLowerCase()
                                      .includes("called/texted")
                                    ? "green"
                                    : "red"
                                : "red",
                              marginRight: "5px",
                            }}
                          />

                          <FontAwesomeIcon
                            icon={faPhone}
                            style={{
                              color: item.requestedDoNotContact
                                ? "red"
                                : item.isInfoConfirmed
                                  ? "green"
                                  : "yellow",
                            }}
                          />

                          {item.isVisited || item.isInfoConfirmed ? (
                            <FontAwesomeIcon
                              icon={faHouseUser}
                              style={{
                                color: item.isVisited
                                  ? "green" // If visited is true, color is green
                                  : "yellow", // If info is confirmed but visited is false, color is yellow
                                marginRight: "5px",
                              }}
                            />
                          ) : null}

                          {item.isVisited || item.isbidSent ? (
                            <FontAwesomeIcon
                              icon={faFileExport}
                              style={{
                                color: item.isbidSent
                                  ? "green" // If bid has been sent, color is green
                                  : "yellow", // If visited but bid has not been sent, color is yellow
                                marginRight: "5px",
                              }}
                            />
                          ) : null}
                        </Col>
                        <Col
                          lg="1"
                          md="1"
                          sm="2"
                          xs="2"
                          className="d-flex flex-row flex-nowrap"
                        >
                          <i className="ni ni-bold-right" />
                        </Col>
                      </Row>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xs="6">
            <Card>
              <CardBody>
                <div>
                  {selectedItem ? (
                    <div>
                      <h3>
                        {selectedItem.name} , {selectedItem.lastName}{" "}
                        <Button
                          size="sm"
                          color="link"
                          onClick={() => setOpenAddModal(true)}
                        >
                          Edit
                        </Button>
                      </h3>
                      <p>
                        <strong>Email:</strong> {selectedItem.email}
                      </p>
                      <p>
                        <strong>Phone:</strong>{" "}
                        {selectedItem.mobile || selectedItem.phone}
                      </p>
                      <p>
                        <strong>City, State:</strong> {selectedItem.city},{" "}
                        {selectedItem.state}
                      </p>
                      <p>
                        <strong>Created At:</strong>{" "}
                        {new Date(selectedItem.createdAt).toLocaleString()}
                      </p>
                      <p>
                        <strong>Origin:</strong> {selectedItem.origin}
                      </p>
                      <p>
                        <strong>Visite?</strong> {selectedItem.isVisited}
                      </p>
                      <p>
                        <strong>Bid Sent?</strong> {selectedItem.isBidSent}
                      </p>
                      {/* Add more fields as necessary */}

                      {/* Add the tabs here */}
                      <Nav tabs className="nav-fill flex-column flex-sm-row">
                        <NavItem>
                          <NavLink
                            className={nestedTab === "1" ? "active" : ""}
                            onClick={() => setNestedTab("1")}
                          >
                            Address(es)
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={nestedTab === "2" ? "active" : ""}
                            onClick={() => setNestedTab("2")}
                          >
                            Property Information
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={nestedTab === "3" ? "active" : ""}
                            onClick={() => setNestedTab("3")}
                          >
                            Zillow Info
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={nestedTab === "4" ? "active" : ""}
                            onClick={() => setNestedTab("4")}
                          >
                            Comments and Notes
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <Col md="12">
                        <TabContent activeTab={nestedTab}>
                          <TabPane tabId="1">
                            {loadingAddresses ? (
                              <p>Loading...</p>
                            ) : (
                              <div className="d-flex flex-column gap-2">
                                {addresses.length === 0 ? (
                                  <div className="text-center font-italic mt-3">
                                    No addresses found
                                  </div>
                                ) : (
                                  addresses?.map((address, key, allAddresses) => (
                                    <div
                                      style={{
                                        gap: 0,
                                      }}
                                      className="d-flex flex-column "
                                      key={address.id}
                                    >
                                      {allAddresses.length === 1 ? "" : address.title ? address.title : `Address ${key + 1}`}
                                      <p className="mb-0">
                                        <strong>City, State:</strong>{" "}
                                        {address.city}, {address.state}
                                      </p>
                                      <p>{address.address}</p>
                                    </div>
                                  ))
                                )}
                              </div>
                            )}
                          </TabPane>
                          <TabPane tabId="2">
                            {(!selectedItem?.addressesId || selectedItem?.addressesId?.length === 0) ? (
                              <>
                                <p>
                                  <strong>Acreage:</strong> {selectedItem.acreage}
                                </p>
                                <p>
                                  <strong>Built in:</strong>{" "}
                                  {selectedItem.yearBuilt}
                                </p>
                              </>)
                              : (selectedItem?.addressesId?.length > 0 && (
                                <>
                                  {addresses.map((address, index, allAddresses) => (
                                    <>
                                      {allAddresses.length === 1 ? "" : <p><strong>{address.title || `Address ${index + 1}`}</strong></p>}

                                      <p>Acreage: {address.acreage || 'N/A'}</p>
                                      <p>Built in: {address.yearBuilt || 'N/A'}</p>
                                    </>
                                  ))}
                                </>
                              )
                              )}
                          </TabPane>
                          <TabPane tabId="3"></TabPane>
                          <TabPane tabId="4">
                            <CustomerNotesTimeline
                              internal={internal}
                              entityId={selectedItem.id}
                              entityType={COLLECTIONS.leads}
                              notes={notes}
                              setError={setError}
                            />
                          </TabPane>
                        </TabContent>
                      </Col>
                      {/* End of tabs */}
                    </div>
                  ) : (
                    <p>Select a lead to see details</p>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <CustomerModal
        isLead
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        selectedItem={selectedItem}
      />

      <FilterModal
        open={openFilterModal}
        toggle={() => setOpenFilterModal(!openFilterModal)}
        filters={filters}
        setFilters={setFilters}
        applyFilters={applyFilters}
      />
    </>
  );
}

export default LeadsView;
