/* eslint-disable react-hooks/exhaustive-deps */
import MigrateContactsButton from "components/Contacts/MigrateContactsButton";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

function AdminSettings() {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="mt-5" xs="12">
            <Card>
              <CardHeader>Contacts</CardHeader>
              <CardBody className="d-flex gap-2 align-items-center justify-content-between">
                <div>
                  <h3>Migrate contacts</h3>
                  <p>
                    Migrate clients and leads contacts to accept multiple
                    contacts.
                  </p>
                </div>

                <MigrateContactsButton />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default AdminSettings;
