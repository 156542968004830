import { ImageViewer } from "react-iv-viewer";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

export function ProjectImageModal({
  open,
  onClose,
  selectedItem,
  onDownload,
  onDelete,
}) {
  console.log(selectedItem);
  const onDeleteFile = async () => {
    await onDelete();
    onClose();
  };
  return (
    <Modal
      isOpen={open}
      toggle={onClose}
      size="lg"
      className="modal-dialog-centered"
    >
      <ModalHeader>{selectedItem?.name || "Image"}</ModalHeader>
      <ModalBody className="w-100">
        <Row width="1600px">
          <ImageViewer
            className="img-fluid w-100 bg-transparent mx-auto"
            img={selectedItem?.url}
            width={"100%"}
            height={"600px"} // Increased height
            style={{ background: "black" }}
            zoomStep={50}
            defaultZoom={100}
            maxZoom={500}
            snapView={true}
            refreshOnResize={true}
            zoomOnMouseWheel={true}
            hasZoomButtons={true}
          />
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={onDeleteFile}>
          <i className="fas fa-trash" />
        </Button>
        <Button color="primary" onClick={onDownload}>
          <i className="fas fa-download" />
        </Button>
        <Button color="light" onClick={onClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
