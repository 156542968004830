import { nanoid } from "nanoid";

export const getIsMac = () => {
  return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
};

export const getNestedValue = (obj, path) => {
  return path.split('.').reduce((accumulator, key) => {
    const match = key.match(/(\w+)\[(\d+)\]/);
    if (match) {
      const arrayKey = match[1];
      const index = parseInt(match[2], 10);
      return accumulator && accumulator[arrayKey] && accumulator[arrayKey][index] !== undefined
        ? accumulator[arrayKey][index]
        : undefined;
    }
    return accumulator && accumulator[key] !== undefined ? accumulator[key] : undefined;
  }, obj);
};

export const transformData = (data) => {
  if (data) {
    const keys = Object.keys(data);
    const items = keys.reduce((result, key) => {
      const item = data[key];
      result.push({
        ...item,
        id: key,
      });
      return result;
    }, []);
    if(items.length > 0 && items[0].name){
      return items.sort((a, b) => a.name.localeCompare(b.name));
    }
    return items;
  } else {
    return [];
  }
};

// Debounce function
export const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export const generateNanoId = () => {
  return nanoid();
};

export const handleNumberInput = (e) => {
  const value = e.target.value.replace(/[^0-9]/g, '');
  e.target.value = value;
};

export const getFlatRoutes = (routes) => {
  let flatRoutes = [];
  routes.forEach((route) => {
    if (route.collapse) {
      flatRoutes = flatRoutes.concat(getFlatRoutes(route.views));
    } else {
      flatRoutes.push(route);
    }
  });
  return flatRoutes;
};

export const getPathRouteIndex = (pathname, routes) => {
  const index = routes.findIndex((route) => {
    const fullPath = `${route.layout}${route.path}`; // Prepend the layout to the path
    const routeParts = fullPath.split("/").filter(Boolean);
    const pathParts = pathname.split("/").filter(Boolean);

    if (routeParts.length !== pathParts.length) return false;

    return routeParts.every((part, index) => {
      // Match if it's a dynamic segment (starts with :) or the exact part matches
      return part.startsWith(":") || part === pathParts[index];
    });
  });

  return index !== -1 ? index : -1;
};

/**
 * Filters the routes based on the user's role.
 *
 * @param {Array} routes - The array of route objects.
 * @param {string} role - The user's role.
 * @param {boolean} noShortCut - Whether to filter out routes with shortcuts. 
 * @returns {Array} - The filtered array of routes that the user has access to.
 */
export const filterRoutesByRole = (routes, role, noShortCut) => {
  const filteredRoutes = routes.reduce((acc, route) => {
    if(route.shortcut && noShortCut){
      return acc;
    }
    if (route.collapse) {
      // If the route has a collapse property, process its views
      const filteredViews = route.views?.filter((view) => {
        if (view.isProtected) {
          return view.permissions?.includes(role);
        }
        return true; // Include unprotected views
      });

      if (filteredViews?.length) {
        acc.push({ ...route, views: filteredViews });
      }
    } else {
      // Process non-collapsed routes
      if (route.isProtected) {
        if (route.permissions?.includes(role)) {
          acc.push(route);
        }
      } else {
        acc.push(route);
      }
    }

    return acc;
  }, []);

  return filteredRoutes;
};


export const isImage = (fileName) => {
  if (typeof fileName !== "string" || !fileName) return false;
  const imageExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".tiff",
    ".ico",
    ".webp",
    ".svg",
  ];
  const extension = fileName?.split(".").pop().toLowerCase();
  return imageExtensions.includes(`.${extension}`);
};



