import { useFirebase } from "model/context/firebase.context";
import { useEffect, useState } from "react";
import "react-iv-viewer/dist/react-iv-viewer.css";
import { Button, Card, CardBody, CardHeader, Table } from "reactstrap";
import { isImage } from "utils";
import { ProjectImageFullscreen } from "./ProjectImageFullscreen";
import { ProjectUploadImageModal } from "./ProjectUploadImageModal";

export function ProjectImagesCard({
  internal,
  images,
  setImages,
  setError,
  onDelete,
}) {
  const { storage } = useFirebase();
  const [openModal, setModalOpen] = useState(false);
  const onUpdate = (image) => {
    setImages((prev) => [image, ...prev]);
  };
  const [imagesUrls, setImagesUrls] = useState([]);

  useEffect(() => {
    const fetchImagesUrls = async () => {
      const urls = await Promise.all(
        images.map(async (image) => {
          return await storage.getFileUrl(image.fullPath);
        })
      );
      setImagesUrls(urls);
    };
    fetchImagesUrls();
  }, [images, storage]);

  return (
    <>
      <Card className="h-100 no-print">
        <CardHeader>
          <div className="d-flex">
            <h3>Images</h3>
            <Button
              size="sm"
              color="link"
              type="button"
              className="ml-auto text-nowrap"
              onClick={() => {
                setModalOpen(true);
              }}
            >
              Upload
            </Button>
          </div>
        </CardHeader>
        <CardBody>
          {images?.length ? (
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">Name</th>
                </tr>
              </thead>

              <tbody className="list">
                {images.map((image, index) => {
                  return (
                    <tr key={image.name}>
                      <td style={{ gap: 50 }} className="d-flex">
                        {isImage(image.name) ? (
                          <>
                            <ProjectImageFullscreen
                              image={imagesUrls[index]}
                              onDownload={() => {
                                storage.download(image.fullPath);
                              }}
                              onDeleteFile={async () => {
                                await storage.delete(image.fullPath);
                                onDelete(image);
                              }}
                            />
                            <Button
                              color="link"
                              onClick={() => {
                                storage.download(image.fullPath);
                              }}
                            >
                              {image.name}
                            </Button>
                          </>
                        ) : (
                          <Button
                            style={{ gap: 50 }}
                            color="link"
                            className="d-flex m-0 px-2"
                            onClick={() => {
                              storage.download(image.fullPath);
                            }}
                          >
                            <i
                              style={{
                                fontSize: 50,
                                marginRight: "auto",
                                marginLeft: "auto",
                              }}
                              className="fas fa-file-alt mx-auto"
                            />
                            <span className="my-auto ">{image.name}</span>
                          </Button>
                        )}
                        <Button
                          color="link"
                          className="icon-only"
                          onClick={async () => {
                            await storage.delete(image.fullPath);
                            onDelete(image);
                          }}
                        >
                          <i className="ni ni-fat-remove" />
                        </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <p className="text-center">There're no images.</p>
          )}
        </CardBody>
      </Card>
      <ProjectUploadImageModal
        internal={internal}
        openModal={openModal}
        setModalOpen={setModalOpen}
        setError={setError}
        onUpdate={onUpdate}
      />
    </>
  );
}
