/* eslint-disable react-hooks/exhaustive-deps */
import { CustomerModal } from "components/Contacts/CustomerModal";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Row,
} from "reactstrap";
import slugify from "slugify";

function CustomersView() {
  const { search: searchQuery } = useLocation();
  const [search, setSearch] = useState("");
  const { db } = useFirebase();
  const [items, setItems] = useState([]);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [addresses, setAddresses] = useState([]);
  const [loadingAddresses, setLoadingAddresses] = useState(false);

  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.customers, async (data) => {
      if (data) {
        const keys = Object.keys(data);
        const items = keys.reduce((result, key) => {
          const item = data[key];
          result.push({
            ...item,
            id: key,
          });
          return result;
        }, []);

        setItems(items);
        if (searchQuery?.trim()) {
          const params = searchQuery
            .trim()
            .replace(/^[?]/, "")
            .split("&")
            .reduce((result, item) => {
              const [key, value] = item.split("=");
              return {
                ...result,
                [key]: value,
              };
            }, {});
          if (params.lead) {
            const item = items.find((item) => item.id === params.lead);
            if (item) onSelectItem(item);
          }
        }
      } else {
        setItems([]);
      }
      if (loading) setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const onSelectItem = useCallback(
    (item) => {
      setSelectedItem(item);
      setAddresses([]);
      if (loadingAddresses) setLoadingAddresses(false);
    },
    [loadingAddresses]
  );

  useEffect(() => {
    const fetchAddresses = async () => {
      if (selectedItem) {
        setLoadingAddresses(true);
        const addressesPromise = selectedItem.addressesId?.map(
          async (addressId) => {
            const addressSnapshot = await db.get("addresses", addressId);
            const address = addressSnapshot?.val();
            if (address) {
              return { ...address, id: addressId };
            }
            return null;
          }
        );
        if (addressesPromise?.length > 0) {
          const addresses = await Promise.all(addressesPromise);
          const filteredAddresses = addresses.filter(
            (address) => address !== null
          );
          if (filteredAddresses?.length > 0) {
            setAddresses(filteredAddresses);
          }
        }
        setLoadingAddresses(false);
      }
    };
    fetchAddresses();
  }, [selectedItem]);

  useEffect(() => {
    if (!openAddModal && selectedItem) {
      setTimeout(() => {
        onSelectItem(null);
      }, 350);
    }
  }, [openAddModal]);

  return (
    <>
      <SimpleHeader
        search={setSearch}
        actions={[
          {
            label: "Add Customer",
            handler: () => setOpenAddModal(true),
          },
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <Col xs="4">
            <Card>
              <CardBody>
                <ListGroup flush>
                  <ListGroupItemHeading>
                    <Row>
                      <Col lg="6" md="6" sm="6" xs="10">
                        Name
                      </Col>
                      <Col lg="5" className="d-none d-lg-block">
                        City, State
                      </Col>
                      <Col lg="1" md="1" sm="2" xs="2"></Col>
                    </Row>
                  </ListGroupItemHeading>
                  {!items.length && (
                    <ListGroupItem>
                      <p className="text-center">
                        {loading ? "Loading..." : "There are no items yet"}
                      </p>
                    </ListGroupItem>
                  )}
                  {(search
                    ? items.filter((item) => {
                      const opts = { lower: true, replacement: " " };
                      const name = slugify(item.name || "", opts);
                      const lastName = slugify(item.lastName || "", opts);
                      const email = slugify(item.email || "", opts);
                      return (
                        name.includes(search) ||
                        lastName.includes(search) ||
                        email.includes(search)
                      );
                    })
                    : items
                  )
                    .sort((a, b) =>
                      new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1
                    )
                    .map((item) => (
                      <ListGroupItem
                        key={item.id}
                        action
                        onClick={() => {
                          onSelectItem(item);
                        }}
                      >
                        <Row>
                          <Col
                            lg="6"
                            md="6"
                            sm="6"
                            xs="10"
                            className="text-bold text-nowrap text-truncate"
                          >
                            {item.name}, {item.lastName}
                          </Col>
                          <Col
                            lg="5"
                            className="d-none d-lg-block text-nowrap text-truncate"
                          >
                            {item.city}, {item.state}
                          </Col>
                          <Col
                            lg="1"
                            md="1"
                            sm="2"
                            xs="2"
                            className="d-flex flex-row flex-nowrap"
                          >
                            <i className="ni ni-bold-right" />
                          </Col>
                        </Row>
                      </ListGroupItem>
                    ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
          <Col xs="8">
            <Card>
              <CardBody>
                <div>
                  {selectedItem ? (
                    <div>
                      <h3>
                        {selectedItem.name} {selectedItem.lastName}
                        <Button
                          size="sm"
                          color="link"
                          onClick={() => setOpenAddModal(true)}
                        >
                          Edit
                        </Button>
                      </h3>
                      <p>
                        <strong>Email:</strong> {selectedItem.email}
                      </p>
                      <p>
                        <strong>Phone:</strong>{" "}
                        {selectedItem.mobile || selectedItem.phone}
                      </p>

                      <p>
                        <strong>Created At:</strong>{" "}
                        {new Date(selectedItem.createdAt).toLocaleString()}
                      </p>
                      <div>
                        <strong className="mb-0">Addresses:</strong>
                        {loadingAddresses ? (
                          <p>Loading...</p>
                        ) : (
                          <div className="d-flex flex-column gap-2 ml-2">
                            {addresses.length === 0 ? (
                              <div className="text-center font-italic">
                                No addresses found
                              </div>
                            ) : (
                              addresses?.map((address, key) => (
                                <div
                                  style={{
                                    gap: 0,
                                  }}
                                  className="d-flex flex-column "
                                  key={address.id}
                                >
                                  {address.title || `Address ${key + 1}`}
                                  <p className="mb-0">
                                    <strong>City, State:</strong> {address.city}
                                    , {address.state}
                                  </p>
                                  <p>{address.address}</p>
                                </div>
                              ))
                            )}
                          </div>
                        )}
                      </div>
                      {/* Add more fields as necessary */}
                    </div>
                  ) : (
                    <p>Select a lead to see details</p>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <CustomerModal
        open={openAddModal}
        onClose={() => setOpenAddModal(false)}
        selectedItem={selectedItem}
      />
    </>
  );
}

export default CustomersView;
