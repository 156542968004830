// File: views/pages/maps/MapsView.js

import classnames from "classnames";
import { useRole } from "hooks/useRole";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import LeadsMap from "./mapsProUtah/leadsMap";

// import ClientMap from "./components/ClientMap";

const MapsView = () => {
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const { isProtected, hasAccess } = useRole();

  useEffect(() => {
    if (isProtected && !hasAccess) {
      navigate("/");
    }
  }, [isProtected, hasAccess, navigate]);

  return (
    <Container className="mt-4" fluid>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            Leads
          </NavLink>
        </NavItem>
        {/* <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Client
          </NavLink>
        </NavItem> */}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <LeadsMap />
        </TabPane>
        {/* <TabPane tabId="2">
          <ClientMap />
        </TabPane> */}
      </TabContent>
    </Container>
  );
};

export default MapsView;
