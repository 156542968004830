/* eslint-disable react-hooks/exhaustive-deps */
import { nanoid } from "nanoid";
import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
} from "reactstrap";
import slugify from "slugify";

export function ProjectFormCard({
  selectedItem,
  errors,
  customer,
  customers,
  leads,
  setCustomer,
  updateCustomer,
}) {
  const touched = useRef(false);
  const options = [...customers, ...leads];
  const [ceilingHeight, setCeilingHeight] = useState("");

  // Load the customer object if we have a customerId but haven't yet loaded the actual record
  useEffect(() => {
    if (!touched.current && selectedItem?.customerId && !customer) {
      setCustomer(options.find((item) => item.id === selectedItem.customerId));
    }
  }, [options]);

  // Handle Ceiling Height Input / Update
  const handleCeilingHeightChange = (e) => {
    setCeilingHeight(e.target.value);
  };

  const updateCustomerCeilingHeight = () => {
    if (ceilingHeight && customer) {
      const height = parseFloat(ceilingHeight);
      if (!isNaN(height)) {
        updateCustomer({ ...customer, ceilingHeight: height });
      }
      setCeilingHeight("");
    }
  };

  const handleCeilingHeightKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      updateCustomerCeilingHeight();
    }
  };

  // Copy Address to clipboard
  const handleCopyAddress = () => {
    if (!customer) return;

    // Build a multiline string for the address
    const addressLines = [
      customer.address || "",
      `${customer.city || ""}, ${customer.state || ""} ${
        customer.zipCode || ""
      }`.trim(),
    ].filter(Boolean); // remove empty lines

    const addressText = addressLines.join("\n");

    // Use Clipboard API
    navigator.clipboard
      .writeText(addressText)
      .catch(() => console.error("Unable to copy address to clipboard."));
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <Col sm="6" md="8">
            <Row>
              <Col md="12" lg="8">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Name
                    <sup className={!!errors?.name ? "text-danger" : ""}>
                      * {errors.name}
                    </sup>
                  </label>
                  <Input
                    required
                    name="name"
                    className={`form-control text-dark ${
                      errors?.name ? "is-invalid" : ""
                    }`}
                    placeholder="Project Name / Description"
                    type="text"
                    defaultValue={selectedItem?.name}
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="4">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Status
                    <sup className={!!errors?.status ? "text-danger" : ""}>
                      * {errors.status}
                    </sup>
                  </label>
                  <Input
                    required
                    name="status"
                    className={`form-control text-dark ${
                      errors?.status ? "is-invalid" : ""
                    }`}
                    placeholder="Project status"
                    type="select"
                    defaultValue={selectedItem?.status || "new-bid"}
                  >
                    <option value="new-bid">New Bid</option>
                    <option value="bid-submitted">Bid submitted</option>
                    <option value="accepted">Accepted</option>
                    <option value="work-in-progress">Work in progress</option>
                    <option value="paused">Paused</option>
                    <option value="waiting-for-customer-payment">
                      Waiting for customer payment
                    </option>
                    <option value="paid">Paid</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="12" lg="8">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Customer
                    <sup className={!!errors?.customer ? "text-danger" : ""}>
                      * {errors.customer}
                    </sup>
                  </label>
                  <Input
                    name="customerId"
                    type="text"
                    readOnly
                    hidden
                    defaultValue={customer?.id}
                  />
                  <Input
                    required
                    name="customer"
                    className={`form-control text-dark ${
                      errors?.customer ? "is-invalid" : ""
                    }`}
                    placeholder="Customer"
                    type="text"
                    defaultValue={selectedItem?.customer}
                    list="customers"
                    onInput={(e) => {
                      const { value } = e.target;
                      const opts = { lower: true };
                      const exists = options.find(
                        (item) =>
                          slugify(item.name, opts) === slugify(value, opts)
                      );
                      setCustomer(exists);
                      touched.current = true;
                    }}
                  />
                  <datalist id="customers">
                    {options.map((cust, key) => (
                      <option key={key} value={cust.name}>
                        {cust.name}
                      </option>
                    ))}
                  </datalist>
                </FormGroup>
              </Col>
              <Col md="12" lg="4">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Area
                  </label>
                  <Input
                    name="projectArea"
                    className={`form-control text-dark ${
                      errors?.name ? "is-invalid" : ""
                    }`}
                    placeholder="Project Area"
                    type="number"
                    defaultValue={selectedItem?.projectArea}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md="4" lg="4">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Date OnBoarded
                    <sup className={!!errors?.date ? "text-danger" : ""}>
                      * {errors.date}
                    </sup>
                  </label>
                  <Input
                    required
                    name="date"
                    className={`form-control text-dark ${
                      errors?.date ? "is-invalid" : ""
                    }`}
                    placeholder="Date"
                    type="date"
                    defaultValue={
                      selectedItem?.date ||
                      new Date().toISOString().replace(/T.*/, "")
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="4" lg="4">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Date Started
                  </label>
                  <Input
                    name="dateStarted"
                    className={`form-control text-dark ${
                      errors?.date ? "is-invalid" : ""
                    }`}
                    placeholder="Date"
                    type="date"
                    defaultValue={
                      selectedItem?.dateStarted ||
                      new Date().toISOString().replace(/T.*/, "")
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="4" lg="4">
                <FormGroup>
                  <label className="form-control-label font-weight-light">
                    Date Finished
                  </label>
                  <Input
                    name="dateFinished"
                    className={`form-control text-dark ${
                      errors?.date ? "is-invalid" : ""
                    }`}
                    placeholder="Date"
                    type="date"
                    defaultValue={
                      selectedItem?.dateFinished ||
                      new Date().toISOString().replace(/T.*/, "")
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>

          <Col sm="6" md="4">
            <div className="float-right">
              <FormGroup>
                <label className="form-control-label font-weight-light">
                  PO #
                  <sup className={!!errors?.number ? "text-danger" : ""}>
                    * {errors.number}
                  </sup>
                </label>
                <Input
                  required
                  name="number"
                  className={`form-control text-dark ${
                    errors?.number ? "is-invalid" : ""
                  }`}
                  placeholder="Project Number"
                  type="text"
                  defaultValue={selectedItem?.number || nanoid(8)}
                />
              </FormGroup>

              {customer && (
                <div>
                  {/* Copy Address Button */}
                  <Button
                    size="sm"
                    color="link"
                    onClick={handleCopyAddress}
                    className="mt-2 mb-2 text-nowrap"
                  >
                    <i className="fa fa-clipboard" aria-hidden="true"></i>
                  </Button>

                  {/* Display address lines */}
                  <p className="small m-0 p-0">{customer?.address}</p>
                  <p className="small m-0 p-0">
                    {customer?.city}, {customer?.state}
                  </p>
                  <p className="small m-0 p-0">{customer?.zipCode}</p>
                  <p className="small m-0 p-0">
                    {customer?.mobile || customer?.phone}
                  </p>

                  {/* Ceiling Height */}
                  {customer?.ceilingHeight ? (
                    <p className="small m-0 p-0">
                      Ceiling Height: {customer.ceilingHeight}ft
                    </p>
                  ) : (
                    <FormGroup>
                      <hr className="my-2" />
                      <div className="d-flex align-items-center">
                        <Label className="small m-0 p-0 mr-2 mb-0">
                          Ceiling Height
                        </Label>
                        <Input
                          name="ceilingHeight"
                          className="form-control text-dark"
                          placeholder="10"
                          type="text"
                          step="0.1"
                          pattern="[0-9]*\.?[0-9]*"
                          value={ceilingHeight}
                          onChange={handleCeilingHeightChange}
                          onKeyPress={handleCeilingHeightKeyPress}
                          onBlur={updateCustomerCeilingHeight}
                          style={{ width: "auto" }}
                        />
                        <Label className="small m-0 p-0 mr-2 mb-0">ft</Label>
                      </div>
                      <hr className="my-2" />
                    </FormGroup>
                  )}

                  <p className="small">{customer?.email}</p>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
