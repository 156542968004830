import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Table,
  Input,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import { ProjectTicketModal } from "components/Project/ProjectTicketModal"; // Adjust path as needed

const TicketsOverview = () => {
  const [projectTickets, setProjectTickets] = useState({});
  const [showAll, setShowAll] = useState(false);
  const [expandedProjects, setExpandedProjects] = useState({});
  const [error, setError] = useState(null);

  // Controls the "Add/Edit Ticket" modal
  const [modalOpen, setModalOpen] = useState(false);

  // Holds the ticket currently being edited (null when adding)
  const [editTicket, setEditTicket] = useState(null);

  const { db } = useFirebase();

  useEffect(() => {
    // Listen for changes in the projects collection
    const unsubscribeProjects = db.listen(COLLECTIONS.projects, (data) => {
      if (!data) {
        setProjectTickets({});
        return;
      }

      const newProjectTickets = {};

      for (const [projectId, projectValue] of Object.entries(data)) {
        if (!projectValue || !projectValue.tickets) {
          continue; // Skip projects without 'tickets'
        }

        const projectName = projectValue.name || "Unknown Project";
        const ticketsArray = Object.entries(projectValue.tickets).map(
          ([ticketId, ticketValue]) => ({
            ticketId,
            projectId,
            projectName,
            ...ticketValue, // includes name, createdAt, createdBy, checked, etc.
          })
        );

        newProjectTickets[projectId] = {
          projectName,
          tickets: ticketsArray,
        };
      }

      setProjectTickets(newProjectTickets);
    });

    // Cleanup subscription on unmount
    return () => unsubscribeProjects();
  }, [db]);

  const handleToggleExpand = (projectId) => {
    setExpandedProjects((prev) => ({
      ...prev,
      [projectId]: !prev[projectId],
    }));
  };

  // Toggle the "checked" state of a ticket (mark as done/undone)
  const handleToggleChecked = async (ticket) => {
    const updated = { ...ticket, checked: !ticket.checked };

    // Update local state first (optimistic)
    setProjectTickets((prev) => {
      const next = { ...prev };
      const project = next[ticket.projectId];
      if (!project) return prev;

      const idx = project.tickets.findIndex(
        (t) => t.ticketId === ticket.ticketId
      );
      if (idx === -1) return prev;

      const updatedTickets = [...project.tickets];
      updatedTickets[idx] = updated;
      next[ticket.projectId] = { ...project, tickets: updatedTickets };
      return next;
    });

    // Persist to Firestore
    try {
      const projectSnap = await db.get(COLLECTIONS.projects, ticket.projectId);
      if (!projectSnap) {
        throw new Error("Project not found");
      }
      const projectPlain = JSON.parse(JSON.stringify(projectSnap));

      if (!projectPlain.tickets) {
        projectPlain.tickets = {};
      }
      projectPlain.tickets[ticket.ticketId] = {
        ...(projectPlain.tickets[ticket.ticketId] || {}),
        ...updated,
      };
      await db.update(COLLECTIONS.projects, ticket.projectId, projectPlain);
    } catch (e) {
      console.error("Failed to update ticket:", e);
      setError(e?.message || "Could not update the ticket");
    }
  };

  // Handle editing a ticket (opens the modal with ticket data)
  const handleEditTicket = (ticket) => {
    // The modal’s code expects `editTicket.id` for the ticket ID.
    setEditTicket({ ...ticket, id: ticket.ticketId });
    setModalOpen(true);
  };

  // Handle deleting a ticket
  const handleDeleteTicket = async (ticket) => {
    try {
      // Update local state first
      setProjectTickets((prev) => {
        const next = { ...prev };
        const project = next[ticket.projectId];
        if (!project) return prev;
        // Filter out the ticket we're deleting
        project.tickets = project.tickets.filter(
          (t) => t.ticketId !== ticket.ticketId
        );
        next[ticket.projectId] = project;
        return next;
      });

      // Then remove it from Firestore
      const projectSnap = await db.get(COLLECTIONS.projects, ticket.projectId);
      if (!projectSnap) {
        throw new Error("Project not found");
      }
      const projectPlain = JSON.parse(JSON.stringify(projectSnap));
      if (projectPlain.tickets && projectPlain.tickets[ticket.ticketId]) {
        delete projectPlain.tickets[ticket.ticketId];
      }
      await db.update(COLLECTIONS.projects, ticket.projectId, projectPlain);
    } catch (e) {
      console.error("Failed to delete ticket:", e);
      setError(e.message || "Could not delete the ticket");
    }
  };

  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col md="12">
          <Card className="shadow">
            <CardHeader className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Tickets Overview</h5>

              {/* Add Ticket Button: creates a brand-new ticket */}
              <Button
                color="primary"
                onClick={() => {
                  setEditTicket(null); // No existing ticket => new
                  setModalOpen(true);
                }}
              >
                Add Ticket
              </Button>
            </CardHeader>
            <CardBody>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}

              {/* Checkbox to toggle "show all" vs. "only unchecked" */}
              <div className="d-flex align-items-center mb-3">
                <Input
                  type="checkbox"
                  checked={showAll}
                  onChange={() => setShowAll(!showAll)}
                  className="me-2"
                />
                <span>Show All Tickets</span>
              </div>

              {/* Render all projects that have tickets */}
              {Object.entries(projectTickets).map(([projectId, project]) => {
                // If "showAll" is false, hide checked tickets
                const displayedTickets = showAll
                  ? project.tickets
                  : project.tickets.filter((t) => !t.checked);

                // Skip if no displayed tickets (and showAll is false)
                if (!showAll && displayedTickets.length === 0) {
                  return null;
                }

                const isExpanded = expandedProjects[projectId] || false;
                const totalTickets = project.tickets.length;
                const uncheckedTickets = project.tickets.filter(
                  (t) => !t.checked
                ).length;

                return (
                  <Card key={projectId} className="mb-3">
                    <CardHeader className="d-flex justify-content-between align-items-center">
                      <strong>
                        <Link to={`/admin/projects/${projectId}`}>
                          {project.projectName}
                        </Link>{" "}
                        ({uncheckedTickets}/{totalTickets})
                      </strong>
                      <Button
                        size="sm"
                        onClick={() => handleToggleExpand(projectId)}
                      >
                        {isExpanded ? "Hide Tickets" : "Show Tickets"}
                      </Button>
                    </CardHeader>
                    {isExpanded && (
                      <CardBody>
                        <Table responsive striped>
                          <thead>
                            <tr>
                              <th>Check</th>
                              <th>Ticket Name</th>
                              <th>Created By</th>
                              <th>Created At</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayedTickets.map((ticket) => (
                              <tr key={ticket.ticketId}>
                                <td>
                                  <Input
                                    type="checkbox"
                                    checked={!!ticket.checked}
                                    onChange={() => handleToggleChecked(ticket)}
                                  />
                                </td>
                                <td>{ticket.name || "N/A"}</td>
                                <td>{ticket.createdBy || "N/A"}</td>
                                <td>
                                  {ticket.createdAt
                                    ? new Date(
                                        ticket.createdAt
                                      ).toLocaleString()
                                    : "N/A"}
                                </td>
                                <td>
                                  <Button
                                    color="warning"
                                    size="sm"
                                    onClick={() => handleEditTicket(ticket)}
                                    className="me-2"
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    color="danger"
                                    size="sm"
                                    onClick={() => handleDeleteTicket(ticket)}
                                  >
                                    Delete
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                    )}
                  </Card>
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* The modal for adding or editing a ticket */}
      <ProjectTicketModal
        // If editing, pass the ticket's projectId. If adding, projectId=null
        projectId={editTicket ? editTicket.projectId : null}
        openModal={modalOpen}
        setModalOpen={setModalOpen}
        setError={setError}
        editTicket={editTicket}
      />
    </Container>
  );
};

export default TicketsOverview;
