import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import { nanoid } from "nanoid";
import { useFirebase } from "model/context/firebase.context";
import { useAuth } from "model/context/auth.context";
import { COLLECTIONS } from "model/constants";

export function ProjectTicketModal({
  projectId,
  openModal,
  setModalOpen,
  setError,
  editTicket,
}) {
  const { session } = useAuth();
  const { db } = useFirebase();

  const [loading, setLoading] = useState(false);
  const [allProjects, setAllProjects] = useState([]);

  const [ticketData, setTicketData] = useState({
    projectName: "",
    name: "",
    description: "",
    instructions: "",
    instructionsFor: "",
    priority: "Low",
  });

  // If editing an existing ticket, populate fields
  useEffect(() => {
    if (editTicket) {
      setTicketData((prev) => ({
        ...prev,
        name: editTicket.name,
        description: editTicket.description,
        instructions: editTicket.instructions || "",
        instructionsFor: editTicket.instructionsFor || "",
        priority: editTicket.priority,
      }));
    } else {
      setTicketData({
        projectName: "",
        name: "",
        description: "",
        instructions: "",
        instructionsFor: "",
        priority: "Low",
      });
    }
  }, [editTicket]);

  // Fetch the relevant project(s). If projectId is null, we listen to all.
  useEffect(() => {
    if (projectId) {
      // Fetch one project
      db.get(COLLECTIONS.projects, projectId)
        .then((doc) => {
          if (!doc) return;
          const project = JSON.parse(JSON.stringify(doc));
          setTicketData((prev) => ({
            ...prev,
            projectName: project.name || "",
          }));
        })
        .catch((err) => setError(err.message || "Could not fetch project."));
    } else {
      // Listen to all projects
      const unsubscribe = db.listen(COLLECTIONS.projects, (data) => {
        if (!data) {
          setAllProjects([]);
          return;
        }
        // Turn the object into an array
        const mapped = Object.entries(data).map(([id, value]) => ({
          id,
          ...value,
        }));
        setAllProjects(mapped);
      });

      // Cleanup on unmount
      return () => unsubscribe();
    }
  }, [projectId, db, setError]);

  const onSubmitTicket = async () => {
    try {
      setLoading(true);

      // Determine which project to use
      let usedProjectId = projectId;
      if (!usedProjectId) {
        // Use the project selected in the dropdown
        const chosenProject = allProjects.find(
          (p) => p.name === ticketData.projectName
        );
        if (!chosenProject) {
          setError("Please select a valid project.");
          return;
        }
        usedProjectId = chosenProject.id;
      }

      // Fetch that project's data
      const projectSnap = await db.get(COLLECTIONS.projects, usedProjectId);
      if (!projectSnap) {
        setError("Project not found.");
        return;
      }
      const projectPlain = JSON.parse(JSON.stringify(projectSnap));
      if (!projectPlain.tickets) {
        projectPlain.tickets = {};
      }

      if (editTicket) {
        // Overwrite the existing ticket
        const ticketId = editTicket.id;
        projectPlain.tickets[ticketId] = {
          ...projectPlain.tickets[ticketId],
          name: ticketData.name,
          description: ticketData.description,
          instructions: ticketData.instructions,
          instructionsFor: ticketData.instructionsFor,
          priority: ticketData.priority,
        };
      } else {
        // Create a new ticket
        const newId = nanoid(10);
        projectPlain.tickets[newId] = {
          name: ticketData.name,
          description: ticketData.description,
          instructions: ticketData.instructions,
          instructionsFor: ticketData.instructionsFor,
          priority: ticketData.priority,
          createdBy: session.user.displayName || session.user.email,
          createdAt: new Date().toISOString(),
          checked: false,
        };
      }

      await db.update(COLLECTIONS.projects, usedProjectId, projectPlain);
      setModalOpen(false);
    } catch (err) {
      setError(err.message || "Error saving ticket.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={openModal} toggle={() => setModalOpen(false)}>
      <ModalHeader toggle={() => setModalOpen(false)}>
        {editTicket ? "Edit Ticket" : "Add Ticket"}
      </ModalHeader>
      <ModalBody>
        <Row>
          {/* Left side: Project, Ticket Name, Description */}
          <Col xs="12" md="6">
            <FormGroup>
              <Label for="projectName">Project</Label>
              {projectId ? (
                // If projectId is given, we display the name as read-only
                <Input
                  type="text"
                  id="projectName"
                  disabled
                  value={ticketData.projectName}
                />
              ) : (
                // Otherwise, user selects from all projects
                <Input
                  type="select"
                  id="projectName"
                  value={ticketData.projectName}
                  onChange={(e) =>
                    setTicketData((prev) => ({
                      ...prev,
                      projectName: e.target.value,
                    }))
                  }
                >
                  <option value="">-- Select a project --</option>
                  {allProjects.map((proj) => (
                    <option key={proj.id} value={proj.name}>
                      {proj.name}
                    </option>
                  ))}
                </Input>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="ticketName">Ticket Name</Label>
              <Input
                id="ticketName"
                placeholder="Enter ticket name"
                type="text"
                required
                value={ticketData.name}
                onChange={(e) =>
                  setTicketData((prev) => ({ ...prev, name: e.target.value }))
                }
              />
            </FormGroup>

            <FormGroup>
              <Label for="ticketDescription">Ticket Description</Label>
              <Input
                id="ticketDescription"
                placeholder="Describe the ticket"
                type="textarea"
                rows={3}
                required
                value={ticketData.description}
                onChange={(e) =>
                  setTicketData((prev) => ({
                    ...prev,
                    description: e.target.value,
                  }))
                }
              />
            </FormGroup>
          </Col>

          {/* Right side: Instructions, InstructionsFor, Priority */}
          <Col xs="12" md="6">
            <FormGroup>
              <Label for="ticketInstructions">Ticket Instructions</Label>
              <Input
                id="ticketInstructions"
                type="textarea"
                rows={5}
                placeholder="Any additional instructions..."
                value={ticketData.instructions}
                onChange={(e) =>
                  setTicketData((prev) => ({
                    ...prev,
                    instructions: e.target.value,
                  }))
                }
              />
            </FormGroup>

            <FormGroup>
              <Label for="ticketInstructionsFor">Instructions For</Label>
              <Input
                id="ticketInstructionsFor"
                type="text"
                placeholder="Role or person these instructions are for..."
                value={ticketData.instructionsFor}
                onChange={(e) =>
                  setTicketData((prev) => ({
                    ...prev,
                    instructionsFor: e.target.value,
                  }))
                }
              />
            </FormGroup>

            <FormGroup>
              <Label for="ticketPriority">Priority</Label>
              <Input
                id="ticketPriority"
                type="select"
                value={ticketData.priority}
                onChange={(e) =>
                  setTicketData((prev) => ({
                    ...prev,
                    priority: e.target.value,
                  }))
                }
              >
                <option>Low</option>
                <option>Medium</option>
                <option>High</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
        <Button color="danger" onClick={onSubmitTicket} disabled={loading}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}
