import { useEffect, useState } from "react";
import { Button, Col, FormGroup, Input, Row } from "reactstrap";
import { COLLECTIONS } from "model/constants";
import { useFirebase } from "model/context/firebase.context";

export function CustomerModalAddress({
  errors,
  address,
  index,
  isNew,
  onDeleteAddress,
  onChangeAddress,
  isUnique,
}) {
  const [state, setState] = useState(address);
  const [hasMigrated, setHasMigrated] = useState(false);
  const { db } = useFirebase();

  useEffect(() => {
    const fetchMigrationStatus = async () => {
      try {
        const settings = await db.get(COLLECTIONS.settings, "");
        setHasMigrated(settings?.val()?.migrated || false);
      } catch (error) {
        console.error("Error fetching migration status:", error);
        setHasMigrated(false);
      }
    };

    fetchMigrationStatus();
  }, [db]);

  useEffect(() => {
    if (address) {
      setState(address);
    } else {
      setState({});
    }
  }, [address]);

  const onBlur = () => {
    const updatedState = { ...state };

    // If title is empty or undefined, use a fallback value
    if (!updatedState.title && hasMigrated) {
      updatedState.title = !isNew ? `Address ${index + 1}` : "New Address";
    }

    setState(updatedState);
    onChangeAddress(updatedState);
  };

  const handleFillLatLong = async () => {
    const baseAddress = address?.address;
    const city = address?.city;
    const st = address?.state;
    const fullAddress = [baseAddress, city, st, "USA"]
      .filter(Boolean)
      .join(", ");

    if (!fullAddress) {
      console.error("No address provided");
      return;
    }

    const url = `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
      fullAddress
    )}&format=json&addressdetails=1`;

    try {
      console.log("Fetching geocoding data for:", fullAddress);
      const response = await fetch(url);
      const data = await response.json();
      console.log("Fetch result:", data);
      if (data && data.length > 0) {
        const result = data[0];
        const latValue = parseFloat(result.lat);
        const lonValue = parseFloat(result.lon);
        console.log("Coordinates found:", latValue, lonValue);

        let newState = state;
        setState((prev) => {
          newState = {
            ...prev,
            latitude: latValue,
            longitude: lonValue,
          };
          return newState;
        });
        onChangeAddress({
          ...newState,
          latitude: latValue,
          longitude: lonValue,
        });
      } else {
        console.error("No results found for", fullAddress);
      }
    } catch (error) {
      console.error("Error fetching geocoding data:", error);
    }
  };

  const onInputChange = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div>
      {/* Title row with optional "Delete" button */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        {hasMigrated ? (
          <FormGroup className="mb-0 flex-grow-1 mr-2">
            <Input
              name="title"
              className="form-control text-dark h3 border-0 p-0"
              placeholder={!isNew ? `Address ${index + 1}` : "New Address"}
              type="text"
              value={
                state.title || (!isNew ? `Address ${index + 1}` : "New Address")
              }
              onChange={onInputChange}
              onBlur={onBlur}
              style={{ fontSize: "1.25rem", fontWeight: "bold" }}
            />
          </FormGroup>
        ) : (
          <h5 className="mb-0">
            {!isNew ? `Address ${index + 1}` : "New Address"}
          </h5>
        )}

        {!isUnique && (
          <Button
            color="danger"
            size="sm"
            onClick={() => onDeleteAddress(address.id)}
          >
            <i className="fas fa-trash" />
          </Button>
        )}
      </div>

      {/* Two-column layout for address fields */}
      <Row>
        {/* Left column: Address, Address 2, City */}
        <Col md="6">
          <FormGroup>
            <label className="form-control-label font-weight-light">
              Address
              {!state.address?.length && (
                <sup className={!!errors?.address ? "text-danger" : ""}>
                  * {errors?.address}
                </sup>
              )}
            </label>
            <Input
              required
              name="address"
              className={`form-control text-dark ${
                !state.address?.length && errors?.address ? "is-invalid" : ""
              }`}
              placeholder="Address"
              type="text"
              defaultValue={address?.address}
              onBlur={onBlur}
              onChange={onInputChange}
            />
          </FormGroup>

          <FormGroup>
            <label className="form-control-label font-weight-light">
              Address 2
            </label>
            <Input
              name="address2"
              className={`form-control text-dark ${
                errors?.address2 ? "is-invalid" : ""
              }`}
              placeholder="Address 2"
              type="text"
              defaultValue={address?.address2}
              onBlur={onBlur}
              onChange={onInputChange}
            />
          </FormGroup>

          <FormGroup>
            <label className="form-control-label font-weight-light">
              City
              {!state.city?.length && (
                <sup className={!!errors?.city ? "text-danger" : ""}>
                  * {errors?.city}
                </sup>
              )}
            </label>
            <Input
              required
              name="city"
              className={`form-control text-dark ${
                !state.city?.length && errors?.city ? "is-invalid" : ""
              }`}
              placeholder="City"
              type="text"
              defaultValue={address?.city}
              onBlur={onBlur}
              onChange={onInputChange}
            />
          </FormGroup>
        </Col>

        {/* Right column: State, Zip, Coordinates */}
        <Col md="6">
          <FormGroup>
            <label className="form-control-label font-weight-light">
              State
              {!state.state?.length && (
                <sup className={!!errors?.state ? "text-danger" : ""}>
                  * {errors?.state}
                </sup>
              )}
            </label>
            <Input
              required
              name="state"
              className={`form-control text-dark ${
                !state.state?.length && errors?.state ? "is-invalid" : ""
              }`}
              placeholder="State"
              type="text"
              defaultValue={address?.state}
              onBlur={onBlur}
              onChange={onInputChange}
            />
          </FormGroup>

          <FormGroup>
            <label className="form-control-label font-weight-light">
              Zip / Postal Code
              {!state.zipCode?.length && (
                <sup className={!!errors?.zipCode ? "text-danger" : ""}>
                  * {errors?.zipCode}
                </sup>
              )}
            </label>
            <Input
              required
              name="zipCode"
              className={`form-control text-dark ${
                !state.zipCode?.length && errors?.zipCode ? "is-invalid" : ""
              }`}
              placeholder="Zip / Postal Code"
              type="text"
              defaultValue={address?.zipCode}
              onBlur={onBlur}
              onChange={onInputChange}
            />
          </FormGroup>

          <Row className="align-items-center">
            <Col xs="2">
              <Button
                size="sm"
                color="secondary"
                type="button"
                onClick={handleFillLatLong}
              >
                <i className="fas fa-map-marker-alt" />
              </Button>
            </Col>
            <Col xs="5">
              <FormGroup className="mb-0">
                <label className="form-control-label font-weight-light">
                  Latitude
                </label>
                <Input
                  name="latitude"
                  className={`form-control text-dark ${
                    errors?.latitude ? "is-invalid" : ""
                  }`}
                  placeholder="Latitude"
                  type="text"
                  value={state?.latitude}
                  onBlur={onBlur}
                  onChange={onInputChange}
                />
              </FormGroup>
            </Col>
            <Col xs="5">
              <FormGroup className="mb-0">
                <label className="form-control-label font-weight-light">
                  Longitude
                </label>
                <Input
                  name="longitude"
                  className={`form-control text-dark ${
                    errors?.longitude ? "is-invalid" : ""
                  }`}
                  placeholder="Longitude"
                  type="text"
                  value={state?.longitude}
                  onBlur={onBlur}
                  onChange={onInputChange}
                />
              </FormGroup>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
