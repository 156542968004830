import { useState } from "react";
import { ImageViewer } from "react-iv-viewer";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

export function ProjectImageFullscreen({
  image,
  name,
  onDeleteFile,
  onDownload,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const handleDelete = () => {
    toggleModal();
    onDeleteFile();
  };

  const handleDownload = () => {
    toggleModal();
    onDownload();
  };

  return (
    <>
      <img
        style={{ 
          cursor: "pointer", 
          maxWidth: "150px",
          maxHeight: "100px",
          objectFit: "contain"
        }}
        className="img-thumbnail"
        onClick={toggleModal}
        alt={name || "Image"}
        src={image}
      />

      <Modal
        isOpen={isOpen}
        toggle={toggleModal}
        backdrop={true}
        keyboard={true}
        className="modal-dialog-centered modal-white modal-xl"
      >
        <ModalHeader toggle={toggleModal}>
          {name || "Image"}
        </ModalHeader>
        <ModalBody>
          <div className="w-100">
            <ImageViewer
              className="img-fluid w-100 bg-transparent mx-auto"
              img={image}
              width={"100%"}
              height={"600px"}
              style={{ background: "black" }}
              zoomStep={50}
              defaultZoom={100}
              maxZoom={500}
              snapView={true}
              refreshOnResize={true}
              zoomOnMouseWheel={true}
              hasZoomButtons={true}
            />
          </div>
        </ModalBody>
        <ModalFooter className="d-flex gap-2 align-items-center justify-content-center">
          <Button color="danger" onClick={handleDelete}>
            <i className="fas fa-trash" />
          </Button>
          <Button color="primary" onClick={handleDownload}>
            <i className="fas fa-download" />
          </Button>
          <Button color="light" onClick={toggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
