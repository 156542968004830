// File: views/pages/leadsMaps.js

import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useRole } from "hooks/useRole";
import { useFirebase } from "model/context/firebase.context";
import { COLLECTIONS } from "model/constants";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Function to create a custom colored dot icon
const getCustomIcon = (color) => {
  return L.divIcon({
    html: `<div style="background-color: ${color}; 
                     width: 16px; 
                     height: 16px; 
                     border-radius: 50%; 
                     border: 2px solid #fff;"></div>`,
    className: "",
    iconSize: [16, 16],
    iconAnchor: [8, 8],
  });
};

// Helper component to fit map bounds based on the leads positions
const FitBounds = ({ leads }) => {
  const map = useMap();
  useEffect(() => {
    if (leads && leads.length > 0) {
      const bounds = L.latLngBounds(
        leads.map((lead) => [
          parseFloat(lead.latitude),
          parseFloat(lead.longitude),
        ])
      );
      map.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [leads, map]);
  return null;
};

const LeadsMaps = () => {
  const { isProtected, hasAccess } = useRole();
  const { db } = useFirebase(); // Using your custom context
  const navigate = useNavigate();

  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState("all");

  // Protect route if user doesn't have access
  useEffect(() => {
    if (isProtected && !hasAccess) {
      navigate("/");
    }
  }, [isProtected, hasAccess, navigate]);

  // Listen to the "leads" collection using your custom db.listen method
  useEffect(() => {
    const unsubscribe = db.listen(COLLECTIONS.leads, (data) => {
      if (data) {
        const keys = Object.keys(data);
        const allLeads = keys.map((id) => ({
          id,
          ...data[id],
        }));
        // Only include leads with valid latitude/longitude values
        const validLeads = allLeads.filter(
          (lead) =>
            lead.latitude !== undefined &&
            lead.longitude !== undefined &&
            !isNaN(parseFloat(lead.latitude)) &&
            !isNaN(parseFloat(lead.longitude))
        );
        setLeads(validLeads);
      } else {
        setLeads([]);
      }
      setLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [db]);

  // Filter leads based on the selected attribute
  const filteredLeads = leads.filter((lead) => {
    if (filter === "all") return true;
    if (filter === "isInfoConfirmed") return lead.isInfoConfirmed;
    if (filter === "isBidSent") return lead.isBidSent;
    if (filter === "isRejected") return lead.isRejected;
    return true;
  });

  // Default center for the map (fallback location)
  const defaultCenter = [39.5, -111.5];

  return (
    <Container className="mt-4" fluid>
      <h1>Leads Map</h1>
      {loading ? (
        <p>Loading leads...</p>
      ) : (
        <div style={{ position: "relative" }}>
          <MapContainer
            center={defaultCenter}
            zoom={7}
            style={{ height: "500px", width: "100%" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">
                OpenStreetMap</a> contributors'
            />
            {filteredLeads.map((lead) => {
              // Choose marker color based on attribute priority
              let markerColor = "blue";
              if (lead.isRejected) {
                markerColor = "red";
              } else if (lead.isBidSent) {
                markerColor = "green";
              } else if (lead.isInfoConfirmed) {
                markerColor = "yellow";
              }
              return (
                <Marker
                  key={lead.id}
                  position={[
                    parseFloat(lead.latitude),
                    parseFloat(lead.longitude),
                  ]}
                  icon={getCustomIcon(markerColor)}
                >
                  <Popup>
                    <strong>{lead.name || "No name provided"}</strong>
                    <br />
                    Latitude: {lead.latitude}
                    <br />
                    Longitude: {lead.longitude}
                  </Popup>
                </Marker>
              );
            })}
            {/* Fit the map bounds to all markers */}
            <FitBounds leads={filteredLeads} />
          </MapContainer>
          {/* Floating filter container */}
          <div
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              zIndex: 1000,
              background: "white",
              padding: "10px",
              borderRadius: "4px",
              boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
            }}
          >
            <label htmlFor="filterSelect" style={{ marginRight: "5px" }}>
              Filter:
            </label>
            <select
              id="filterSelect"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value="all">All</option>
              <option value="isInfoConfirmed">Info Confirmed</option>
              <option value="isBidSent">Bid Sent</option>
              <option value="isRejected">Rejected</option>
            </select>
            <div style={{ marginTop: "5px", fontWeight: "bold" }}>
              Total Leads: {filteredLeads.length}
            </div>
          </div>
        </div>
      )}
    </Container>
  );
};

export default LeadsMaps;
