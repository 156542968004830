import { COLLECTIONS, ROLES } from "model/constants";
import { useAuth } from "model/context/auth.context";
import { useFirebase } from "model/context/firebase.context";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Spinner, Toast, ToastBody } from "reactstrap";

export default function MigrateContactsButton() {
  const { db } = useFirebase();
  const { userRole } = useAuth();
  const navigate = useNavigate();

  const [hasMigrated, setHasMigrated] = useState();
  const [loading, setLoading] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      if (userRole !== ROLES.ADMIN) {
        navigate("/");
        return;
      }
      setFetchingData(true);
      try {
        const settings = await db.get(COLLECTIONS.settings, "");

        if (settings?.val()?.migrated) return setHasMigrated(true);
        return setHasMigrated(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFetchingData(false);
      }
    };

    fetchData();
  }, [db, navigate, userRole]);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  }, [error]);

  // Helper function to extract property and Zillow information from a contact
  const extractPropertyInfo = (contact) => {
    return {
      // Property Information
      acreage: contact.acreage || "",
      parcelNumber: contact.parcelNumber || "",
      ceilingHeight: contact.ceilingHeight || "",
      year2019value: contact.year2019value || "",
      year2020value: contact.year2020value || "",
      year2021value: contact.year2021value || "",
      year2022value: contact.year2022value || "",
      year2023value: contact.year2023value || "",
      year2024value: contact.year2024value || "",
      hasBasementFinished: contact.hasBasementFinished || false,
      hasBasementFinishedSource: contact.hasBasementFinishedSource || "",
      hasBasement: contact.hasBasement || false,
      hasBasementEntrance: contact.hasBasementEntrance || false,
      pricePerSqft: contact.pricePerSqft || "",
      hoaFee: contact.hoaFee || "",
      special: contact.special || "",
      
      // Zillow Information
      zillowLink: contact.zillowLink || "",
      zillowLastListingDate: contact.zillowLastListingDate || "",
      zillowValue: contact.zillowValue || "",
      zillowAcreage: contact.zillowAcreage || "",
      zillowDescription: contact.zillowDescription || "",
    };
  };

  // Helper function to remove property, Zillow, and address fields from a contact
  const removeFieldsFromContact = (contact) => {
    const newContact = { ...contact };
    
    // Remove property and Zillow fields
    const propertyAndZillowFields = [
      'acreage', 'parcelNumber', 'ceilingHeight',
      'year2019value', 'year2020value', 'year2021value', 'year2022value', 'year2023value', 'year2024value',
      'hasBasementFinished', 'hasBasementFinishedSource', 'hasBasement', 'hasBasementEntrance',
      'pricePerSqft', 'hoaFee', 'special',
      'zillowLink', 'zillowLastListingDate', 'zillowValue', 'zillowAcreage', 'zillowDescription'
    ];
    
    // Remove address fields
    const addressFields = [
      'address', 'address2', 'city', 'state', 'zipCode', 'latitude', 'longitude'
    ];
    
    // Combine all fields to remove
    const fieldsToRemove = [...propertyAndZillowFields, ...addressFields];
    
    fieldsToRemove.forEach(field => {
      delete newContact[field];
    });
    
    return newContact;
  };

  const handleMigration = async () => {
    try {
      if (userRole !== ROLES.ADMIN) {
        navigate("/admin");
        return;
      }

      setLoading(true);
      setError(false);
      setErrorMessage("");
      
      console.log("Starting migration preparation...");
      
      // Fetch all data first
      console.log("Fetching leads...");
      const leads = await db.getAllCollection(COLLECTIONS.leads, "") || [];
      console.log(`Found ${leads?.length || 0} leads`);
      
      console.log("Fetching customers...");
      const customers = await db.getAllCollection(COLLECTIONS.customers, "") || [];
      console.log(`Found ${customers?.length || 0} customers`);
      
      console.log("Fetching existing addresses...");
      const existingAddresses = await db.getAllCollection(COLLECTIONS.addresses, "") || [];
      console.log(`Found ${existingAddresses?.length || 0} existing addresses`);
      
      // Prepare all the data in memory first
      console.log("Preparing lead migrations...");
      const leadMigrations = (leads || []).map(lead => {
        // Extract property and Zillow information
        const propertyInfo = extractPropertyInfo(lead);
        
        // Create address with property info
        const address = {
          title: "Primary Address",
          address: lead.address || "",
          address2: lead.address2 || "",
          city: lead.city || "",
          state: lead.state || "",
          zipCode: lead.zipCode || "",
          latitude: lead.latitude || "",
          longitude: lead.longitude || "",
          // Add property and Zillow information
          ...propertyInfo
        };
        
        // Remove property and address fields from lead
        const cleanedLead = removeFieldsFromContact(lead);
        
        return {
          leadId: lead.id,
          originalLead: lead,
          newAddress: address,
          cleanedLead: cleanedLead
        };
      });
      
      console.log("Preparing customer migrations...");
      const customerMigrations = (customers || []).map(customer => {
        // Extract property and Zillow information
        const propertyInfo = extractPropertyInfo(customer);
        
        // Create address with property info
        const address = {
          title: "Primary Address",
          address: customer.address || "",
          address2: customer.address2 || "",
          city: customer.city || "",
          state: customer.state || "",
          zipCode: customer.zipCode || "",
          latitude: customer.latitude || "",
          longitude: customer.longitude || "",
          // Add property and Zillow information
          ...propertyInfo
        };
        
        // Remove property and address fields from customer
        const cleanedCustomer = removeFieldsFromContact(customer);
        
        return {
          customerId: customer.id,
          originalCustomer: customer,
          newAddress: address,
          cleanedCustomer: cleanedCustomer
        };
      });
      
      console.log("Preparing address updates...");
      const addressUpdates = (existingAddresses || [])
        .filter(address => address && !address.title)
        .map(address => ({
          addressId: address.id,
          updatedAddress: { ...address, title: "Primary Address" }
        }));
      
      // Validate the data
      console.log("Validating migration data...");
      
      // Check for leads without addresses
      const leadsWithoutAddress = (leadMigrations || []).filter(
        item => item && (!item.newAddress.address && !item.newAddress.city)
      );
      if (leadsWithoutAddress?.length > 0) {
        console.warn(`Warning: ${leadsWithoutAddress.length} leads have no address information`);
      }
      
      // Check for customers without addresses
      const customersWithoutAddress = (customerMigrations || []).filter(
        item => item && (!item.newAddress.address && !item.newAddress.city)
      );
      if (customersWithoutAddress?.length > 0) {
        console.warn(`Warning: ${customersWithoutAddress.length} customers have no address information`);
      }
      
      // Confirm with user
      console.log("Migration preparation complete.");
      console.log(`Ready to migrate ${leadMigrations?.length || 0} leads, ${customerMigrations?.length || 0} customers, and update ${addressUpdates?.length || 0} existing addresses.`);
      console.log("Proceeding with database updates...");
      
      // Now perform the actual database operations
      try {
        // Create new addresses for leads and update leads
        console.log("Migrating leads...");
        const leadResults = await Promise.all(
          (leadMigrations || []).map(async ({ leadId, newAddress, cleanedLead }) => {
            const newAddressId = await db.insert("addresses", newAddress);
            const leadWithAddress = { 
              ...cleanedLead, 
              addressesId: [newAddressId] 
            };
            await db.update(COLLECTIONS.leads, leadId, leadWithAddress);
            return { leadId, addressId: newAddressId };
          })
        );
        console.log(`Successfully migrated ${leadResults?.length || 0} leads`);
        
        // Create new addresses for customers and update customers
        console.log("Migrating customers...");
        const customerResults = await Promise.all(
          (customerMigrations || []).map(async ({ customerId, newAddress, cleanedCustomer }) => {
            const newAddressId = await db.insert("addresses", newAddress);
            const customerWithAddress = { 
              ...cleanedCustomer, 
              addressesId: [newAddressId] 
            };
            await db.update(COLLECTIONS.customers, customerId, customerWithAddress);
            return { customerId, addressId: newAddressId };
          })
        );
        console.log(`Successfully migrated ${customerResults?.length || 0} customers`);
        
        // Update existing addresses
        console.log("Updating existing addresses...");
        const addressResults = await Promise.all(
          (addressUpdates || []).map(async ({ addressId, updatedAddress }) => {
            await db.update(COLLECTIONS.addresses, addressId, updatedAddress);
            return { addressId };
          })
        );
        console.log(`Successfully updated ${addressResults?.length || 0} existing addresses`);
        
        // Update settings to mark migration as complete
        console.log("Updating migration status...");
        await db.update(COLLECTIONS.settings, "", {
          migrated: true,
          propertyInfoMigrated: true,
          addressFieldsRemoved: true
        });
        
        console.log("Migration completed successfully!");
        setHasMigrated(true);
      } catch (dbError) {
        console.error("Database operation failed:", dbError);
        setError(true);
        setErrorMessage(`Database operation failed: ${dbError.message || dbError}`);
        throw dbError;
      }
    } catch (error) {
      console.error("Error during migration:", error);
      setError(true);
      setErrorMessage(`Migration failed: ${error.message || error}`);
    } finally {
      setLoading(false);
    }
  };

  if (hasMigrated === undefined) return <Spinner size="sm" />;

  return (
    <>
      {hasMigrated ? (
        <Button disabled color="outline-success">
          Contacts have been migrated
        </Button>
      ) : (
        <Button
          disabled={fetchingData || loading}
          onClick={handleMigration}
          color="primary"
        >
          {loading ? (
            <>
              Migrating... <Spinner size="sm" className="ml-2" />{" "}
            </>
          ) : (
            "Migrate Contacts"
          )}
        </Button>
      )}
      {error && (
        <div className="position-absolute fixed-top p-3 bg-danger m-2 rounded">
          <Toast>
            <ToastBody className="text-white">
              {errorMessage || "Error migrating contacts"}
            </ToastBody>
          </Toast>
        </div>
      )}
    </>
  );
}
